import PropTypes from "prop-types";
import clsx from "clsx";
import Swiper, { SwiperSlide } from "../../components/swiper";
import BrandLogoOneSingle from "../../components/brand-logo/BrandLogoOneSingle";
import brandLogoData from "../../data/brand-logos/brand-logo-one.json";
import { getAllBrandsRequest } from "../../api/brand.api";
import { useContext, useState, useEffect } from "react";
import { ToastContext } from "../../context/toastContext";
import {
  toast_actions,
  toast_types
} from "../../components/shared/toast/utils/toast";
import useCancellablePromise from "../../api/cancelRequest";

const settings = {
  loop: true,
  autoplay: true,
  grabCursor: true,
  breakpoints: {
    320: {
      slidesPerView: 2
    },
    640: {
      slidesPerView: 3
    },
    1024: {
      slidesPerView: 5
    },
    768: {
      slidesPerView: 4
    }
  }
};

const BrandLogoSliderOne = ({ spaceBottomClass, spaceTopClass }) => {
  const [brands, setBrands] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useContext(ToastContext);

  const { cancellablePromise } = useCancellablePromise();

  const getAllBrands = async () => {
    setIsLoading(true);
    let counter = 1;
    let brandsArray = [];
    try {
      const response = await cancellablePromise(getAllBrandsRequest());
      response.data.forEach((item) => {
        brandsArray.push({
          id: counter,
          image: item.descriptor.symbol
        });
        counter += 1;
      });
      console.log("brands:", brandsArray);
      setBrands(brandsArray);
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: err?.response?.data?.error?.message
        }
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllBrands();
  }, []);

  return (
    <div className={clsx("brand-logo-area", spaceBottomClass, spaceTopClass)}>
      <div className="container">
        <div className="brand-logo-active">
          {brands && (
            <Swiper options={settings}>
              {brands.map((single, key) => (
                <SwiperSlide
                  key={key}
                  style={{ width: "64px", height: "64px" }}
                >
                  <BrandLogoOneSingle data={single} spaceBottomClass="mb-30" />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
      </div>
    </div>
  );
};

BrandLogoSliderOne.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string
};

export default BrandLogoSliderOne;
