// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading_wrapper__EUA\\+9 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading_wrapper__EUA\\+9 > div {
  margin: 0 3px;
}

.loading_dot1__yIHvT {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  animation: loading_scale__uo7kn 0.5s 0.1s ease infinite alternate;
}

.loading_dot2__OUD5u {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  animation: loading_scale__uo7kn 0.5s 0.2s ease infinite alternate;
}

.loading_dot3__qQEg\\+ {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  animation: loading_scale__uo7kn 0.5s 0.3s ease infinite alternate;
}

.loading_dot4__sTdJW {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  animation: loading_scale__uo7kn 0.5s 0.4s ease infinite alternate;
}

@keyframes loading_scale__uo7kn {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.6);
    opacity: 0.5;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/shared/loading/loading.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AADF;;AAIA;EACE,aAAA;AADF;;AAGA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,iEAAA;AAAF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,iEAAA;AACF;;AACA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,iEAAA;AAEF;;AAAA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,iEAAA;AAGF;;AAAA;EACE;IACE,mBAAA;IACA,UAAA;EAGF;EADA;IACE,qBAAA;IACA,YAAA;EAGF;AACF","sourcesContent":["@import \"../../../assets/scss/variables\";\n\n.wrapper {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.wrapper > div {\n  margin: 0 3px;\n}\n.dot1 {\n  width: 10px;\n  height: 10px;\n  border-radius: 50%;\n  animation: scale 0.5s 0.1s ease infinite alternate;\n}\n.dot2 {\n  width: 10px;\n  height: 10px;\n  border-radius: 50%;\n  animation: scale 0.5s 0.2s ease infinite alternate;\n}\n.dot3 {\n  width: 10px;\n  height: 10px;\n  border-radius: 50%;\n  animation: scale 0.5s 0.3s ease infinite alternate;\n}\n.dot4 {\n  width: 10px;\n  height: 10px;\n  border-radius: 50%;\n  animation: scale 0.5s 0.4s ease infinite alternate;\n}\n\n@keyframes scale {\n  from {\n    transform: scale(1);\n    opacity: 1;\n  }\n  to {\n    transform: scale(0.6);\n    opacity: 0.5;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `loading_wrapper__EUA+9`,
	"dot1": `loading_dot1__yIHvT`,
	"scale": `loading_scale__uo7kn`,
	"dot2": `loading_dot2__OUD5u`,
	"dot3": `loading_dot3__qQEg+`,
	"dot4": `loading_dot4__sTdJW`
};
export default ___CSS_LOADER_EXPORT___;
