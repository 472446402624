import { Fragment } from "react";
import { useLocation } from "react-router-dom";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";

const Privacy = () => {
  let { pathname } = useLocation();

  return (
    <Fragment>
      <SEO
        titleTemplate="Privacy"
        description="Privacy page of Neonyte Retail Marketplace"
      />
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb
          pages={[
            { label: "Home", path: "/" },
            {
              label: "Privacy",
              path: pathname
            }
          ]}
        />

        <div className="contact-area pt-100 pb-100">
          <div className="container">
            <div className="custom-row-2">
              <div className="col-12 col-lg-4 col-md-5">
                <div className="contact-info-wrap">
                  <div className="single-contact-info">
                    <div className="contact-icon">
                      <i className="fa fa-phone" />
                    </div>
                    <div className="contact-info-dec">
                      <p>+091 95133 33471</p>
                      <p>+091 95133 33472</p>
                    </div>
                  </div>
                  <div className="single-contact-info">
                    <div className="contact-icon">
                      <i className="fa fa-globe" />
                    </div>
                    <div className="contact-info-dec">
                      <p>
                        <a href="info@simtel.ai">info@simtel.ai</a>
                      </p>
                      <p>
                        <a href="https://simtel.ai">https://simtel.ai</a>
                      </p>
                    </div>
                  </div>
                  <div className="single-contact-info">
                    <div className="contact-icon">
                      <i className="fa fa-map-marker" />
                    </div>
                    <div className="contact-info-dec">
                      <p>No. 36 Emami Nest Flat No 302 </p>
                      <p>8th Main 16th Cross Malleshwaram</p>
                    </div>
                  </div>
                  <div className="contact-social text-center">
                    <h3>Follow Us</h3>
                    <ul>
                      <li>
                        <a href="//facebook.com">
                          <i className="fa fa-facebook" />
                        </a>
                      </li>
                      <li>
                        <a href="//pinterest.com">
                          <i className="fa fa-pinterest-p" />
                        </a>
                      </li>
                      <li>
                        <a href="//thumblr.com">
                          <i className="fa fa-tumblr" />
                        </a>
                      </li>
                      <li>
                        <a href="//vimeo.com">
                          <i className="fa fa-vimeo" />
                        </a>
                      </li>
                      <li>
                        <a href="//twitter.com">
                          <i className="fa fa-twitter" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-8 col-md-7">
                <div class="markdown-heading" dir="auto">
                  <h1 class="heading-element" dir="auto" tabindex="-1">
                    PRIVACY POLICY
                  </h1>
                  <a
                    id="user-content-privacy-policy"
                    class="anchor"
                    href="https://github.com/ravi-simtel/neonexp-blog/blob/main/src/pages/privacy_policy.md#privacy-policy"
                  ></a>
                </div>
                <p dir="auto">
                  <code>Last updated&nbsp;Nov 30, 2024</code>
                </p>
                <div class="markdown-heading" dir="auto">
                  <h2 class="heading-element" dir="auto" tabindex="-1">
                    AGREEMENT TO OUR LEGAL TERMS
                  </h2>
                  <a
                    id="user-content-agreement-to-our-legal-terms"
                    class="anchor"
                    href="https://github.com/ravi-simtel/neonexp-blog/blob/main/src/pages/privacy_policy.md#agreement-to-our-legal-terms"
                  ></a>
                </div>
                <p dir="auto">
                  We are Simulate Intelligence Private Limited, having applied
                  multiple trademarks&nbsp;<strong>simtel.ai</strong>
                  &nbsp;and&nbsp;<strong>neonyte.com</strong>&nbsp;(
                  <strong>"Company," "we," "us," "our"</strong>), a company
                  registered in India at 36 Emami Nest, #302, 2nd Floor, 8th
                  Main 16th Cross Malleswaram, Bengaluru, KA 560055.
                </p>
                <p dir="auto">
                  We operate the website neonyte.com (the "Site"), as well as
                  any other related products and services that refer or link to
                  these legal terms (the "Legal Terms") (collectively, the
                  "Services").
                </p>
                <p dir="auto">
                  We are a web3 platform developing the technologies with
                  decentralized applications and AI technologies for many
                  applications including decentralized e-commerce marketplace,
                  advertising &amp; media SaaS products and identity-related
                  applications
                </p>
                <p dir="auto">
                  You can contact us by phone at&nbsp;
                  <strong>+91 95133 33471</strong>, email at&nbsp;
                  <strong>
                    <a href="mailto:support@neonexp.com">support@neonyte.com</a>
                  </strong>
                  , or by mail to&nbsp;
                  <strong>
                    36 Emami Nest, #302, 2nd Floor, 8th Main 16th Cross
                    Malleswaram, Bengaluru, KA 560003, India.
                  </strong>
                </p>
                <p dir="auto">
                  These Legal Terms constitute a legally binding agreement made
                  between you, whether personally or on behalf of an entity (
                  <strong>"you"</strong>), and Simulate Intelligence Private
                  Limited, concerning your access to and use of the Services.
                  You agree that by accessing the Services, you have read,
                  understood, and agreed to be bound by all of these Legal
                  Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN
                  YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU
                  MUST DISCONTINUE USE IMMEDIATELY.
                </p>
                <p dir="auto">
                  We will provide you with prior notice of any scheduled changes
                  to the Services you are using. The modified Legal Terms will
                  become effective upon posting or notifying you by&nbsp;
                  <a href="mailto:info@simtel.ai">info@simtel.ai</a>, as stated
                  in the email message. By continuing to use the Services after
                  the effective date of any changes, you agree to be bound by
                  the modified terms.
                </p>
                <p dir="auto">
                  The Services are intended for users who are at least 18 years
                  old. Persons under the age of 18 are not permitted to use or
                  register for the Services.
                </p>
                <p dir="auto">
                  We recommend that you print a copy of these Legal Terms for
                  your records.
                </p>
                <div class="markdown-heading" dir="auto">
                  <h2 class="heading-element" dir="auto" tabindex="-1">
                    1. OUR SERVICES
                  </h2>
                  <a
                    id="user-content-1-our-services"
                    class="anchor"
                    href="https://github.com/ravi-simtel/neonexp-blog/blob/main/src/pages/privacy_policy.md#1-our-services"
                  ></a>
                </div>
                <p dir="auto">
                  The information provided when using the Services is not
                  intended for distribution to or use by any person or entity in
                  any jurisdiction or country where such distribution or use
                  would be contrary to law or regulation or which would subject
                  us to any registration requirement within such jurisdiction or
                  country. Accordingly, those persons who choose to access the
                  Services from other locations do so on their own initiative
                  and are solely responsible for compliance with local laws, if
                  and to the extent local laws are applicable.
                </p>
                <p dir="auto">
                  The Services are not tailored to comply with industry-specific
                  regulations You may not use the Services. You may not use the
                  Services in a way that would violate the Digital Personal Data
                  Protection Act (2023).
                </p>
                <div class="markdown-heading" dir="auto">
                  <h2 class="heading-element" dir="auto" tabindex="-1">
                    2. INTELLECTUAL PROPERTY RIGHTS
                  </h2>
                  <a
                    id="user-content-2-intellectual-property-rights"
                    class="anchor"
                    href="https://github.com/ravi-simtel/neonexp-blog/blob/main/src/pages/privacy_policy.md#2-intellectual-property-rights"
                  ></a>
                </div>
                <p dir="auto">
                  <strong>Our intellectual property</strong>
                </p>
                <p dir="auto">
                  We are the owner or the licensee of all intellectual property
                  rights in our Services, including all source code, databases,
                  functionality, software, website designs, audio, video, text,
                  photographs, and graphics in the Services (collectively, the
                  "Content"), as well as the trademarks, service marks, and
                  logos contained therein (the "Marks").
                </p>
                <p dir="auto">
                  Our Content and Marks are protected by copyright and trademark
                  laws (and various other intellectual property rights and
                  unfair competition laws) and treaties in India and around the
                  world.
                </p>
                <p dir="auto">
                  The Content and Marks are provided in or through the Services
                  "AS IS" for your personal, non-commercial use or internal
                  business purpose only.
                </p>
                <p dir="auto">
                  Your use of our Services Subject to your compliance with these
                  Legal Terms, including the&nbsp;
                  <strong>"PROHIBITED ACTIVITIES"</strong>&nbsp;section below,
                  we grant you a non-exclusive, non-transferable, revocable
                  license to: access the Services; and download or print a copy
                  of any portion of the Content to which you have properly
                  gained access. solely for your personal, non-commercial use or
                  internal business purpose.
                </p>
                <p dir="auto">
                  Except as set out in this section or elsewhere in our Legal
                  Terms, no part of the Services and no Content or Marks may be
                  copied, reproduced, aggregated, republished, uploaded, posted,
                  publicly displayed, encoded, translated, transmitted,
                  distributed, sold, licensed, or otherwise exploited for any
                  commercial purpose whatsoever, without our express prior
                  written permission.
                </p>
                <p dir="auto">
                  If you wish to make any use of the Services, Content, or Marks
                  other than as set out in this section or elsewhere in our
                  Legal Terms, please address your request to:&nbsp;
                  <a href="mailto:support@neonexp.com">support@neonyte.com</a>.
                  If we ever grant you the permission to post, reproduce, or
                  publicly display any part of our Services or Content, you must
                  identify us as the owners or licensors of the Services,
                  Content, or Marks and ensure that any copyright or proprietary
                  notice appears or is visible on posting, reproducing, or
                  displaying our Content.
                </p>
                <p dir="auto">
                  We reserve all rights not expressly granted to you in and to
                  the Services, Content, and Marks.
                </p>
                <p dir="auto">
                  Any breach of these Intellectual Property Rights will
                  constitute a material breach of our Legal Terms and your right
                  to use our Services will terminate immediately.
                </p>
                <p dir="auto">
                  <strong>Your submissions</strong>
                </p>
                <p dir="auto">
                  Please review this section and the "PROHIBITED ACTIVITIES"
                  section carefully prior to using our Services to understand
                  the (a) rights you give us and (b) obligations you have when
                  you post or upload any content through the Services.
                </p>
                <p dir="auto">
                  <strong>Submissions:</strong>&nbsp;By directly sending us any
                  question, comment, suggestion, idea, feedback, or other
                  information about the Services ("Submissions"), you agree to
                  assign to us all intellectual property rights in such
                  Submission. You agree that we shall own this Submission and be
                  entitled to its unrestricted use and dissemination for any
                  lawful purpose, commercial or otherwise, without
                  acknowledgment or compensation to you.
                </p>
                <p dir="auto">
                  <strong>
                    You are responsible for what you post or upload:
                  </strong>
                  &nbsp;By sending us Submissions through any part of the
                  Services you: confirm that you have read and agree with our
                  "PROHIBITED ACTIVITIES" and will not post, send, publish,
                  upload, or transmit through the Services any Submission that
                  is illegal, harassing, hateful, harmful, defamatory, obscene,
                  bullying, abusive, discriminatory, threatening to any person
                  or group, sexually explicit, false, inaccurate, deceitful, or
                  misleading; to the extent permissible by applicable law, waive
                  any and all moral rights to any such Submission; warrant that
                  any such Submission are original to you or that you have the
                  necessary rights and licenses to submit such Submissions and
                  that you have full authority to grant us the above-mentioned
                  rights in relation to your Submissions; and warrant and
                  represent that your Submissions do not constitute confidential
                  information. You are solely responsible for your Submissions
                  and you expressly agree to reimburse us for any and all losses
                  that we may suffer because of your breach of (a) this section,
                  (b) any third party&rsquo;s intellectual property rights, or
                  (c) applicable law.
                </p>
                <div class="markdown-heading" dir="auto">
                  <h2 class="heading-element" dir="auto" tabindex="-1">
                    3. USER REPRESENTATIONS
                  </h2>
                  <a
                    id="user-content-3-user-representations"
                    class="anchor"
                    href="https://github.com/ravi-simtel/neonexp-blog/blob/main/src/pages/privacy_policy.md#3-user-representations"
                  ></a>
                </div>
                <p dir="auto">
                  By using the Services, you represent and warrant that: (1) you
                  have the legal capacity and you agree to comply with these
                  Legal Terms; (2) you are not a minor in the jurisdiction in
                  which you reside; (3) you will not access the Services through
                  automated or non-human means, whether through a bot, script or
                  otherwise; (4) you will not use the Services for any illegal
                  or unauthorized purpose; and (5) your use of the Services will
                  not violate any applicable law or regulation.
                </p>
                <p dir="auto">
                  If you provide any information that is untrue, inaccurate, not
                  current, or incomplete, we have the right to suspend or
                  terminate your account and refuse any and all current or
                  future use of the Services (or any portion thereof).
                </p>
                <div class="markdown-heading" dir="auto">
                  <h2 class="heading-element" dir="auto" tabindex="-1">
                    4. PURCHASES AND PAYMENT
                  </h2>
                  <a
                    id="user-content-4-purchases-and-payment"
                    class="anchor"
                    href="https://github.com/ravi-simtel/neonexp-blog/blob/main/src/pages/privacy_policy.md#4-purchases-and-payment"
                  ></a>
                </div>
                <p dir="auto">
                  We accept payments through RazorPay Payment Services and we
                  strictly follow PCI DSS standards and we expect all our
                  vendors to follow them and be aware of the laws associated
                  with it.
                </p>
                <div class="markdown-heading" dir="auto">
                  <h2 class="heading-element" dir="auto" tabindex="-1">
                    5. CANCELLATION
                  </h2>
                  <a
                    id="user-content-5-cancellation"
                    class="anchor"
                    href="https://github.com/ravi-simtel/neonexp-blog/blob/main/src/pages/privacy_policy.md#5-cancellation"
                  ></a>
                </div>
                <p dir="auto">
                  A product purchased on our e-commerce platform can be
                  cancelled as long as it is not shipped by us or or vendor
                  partner and within 24 hours of purchase. Once shipped, it is
                  eligible for returns only if the product or category of
                  products fall under return policy eligibility criteria.
                </p>
                <div class="markdown-heading" dir="auto">
                  <h2 class="heading-element" dir="auto" tabindex="-1">
                    6. PROHIBITED ACTIVITIES
                  </h2>
                  <a
                    id="user-content-6-prohibited-activities"
                    class="anchor"
                    href="https://github.com/ravi-simtel/neonexp-blog/blob/main/src/pages/privacy_policy.md#6-prohibited-activities"
                  ></a>
                </div>
                <p dir="auto">
                  You may not access or use the Services for any purpose other
                  than that for which we make the Services available. The
                  Services may not be used in connection with any commercial
                  endeavors except those that are specifically endorsed or
                  approved by us.
                </p>
                <p dir="auto">As a user of the Services, you agree not to:</p>
                <ul dir="auto">
                  <li>
                    <p dir="auto">
                      Systematically retrieve data or other content from the
                      Services to create or compile, directly or indirectly, a
                      collection, compilation, database, or directory without
                      written permission from us.
                    </p>
                  </li>
                  <li>
                    <p dir="auto">
                      Trick, defraud, or mislead us and other users, especially
                      in any attempt to learn sensitive account information such
                      as user passwords.
                    </p>
                  </li>
                  <li>
                    <p dir="auto">
                      Circumvent, disable, or otherwise interfere with
                      security-related features of the Services, including
                      features that prevent or restrict the use or copying of
                      any Content or enforce limitations on the use of the
                      Services and/or the Content contained therein.
                    </p>
                  </li>
                  <li>
                    <p dir="auto">
                      Disparage, tarnish, or otherwise harm, in our opinion, us
                      and/or the Services. Use any information obtained from the
                      Services in order to harass, abuse, or harm another
                      person.
                    </p>
                  </li>
                  <li>
                    <p dir="auto">
                      Make improper use of our support services or submit false
                      reports of abuse or misconduct.
                    </p>
                  </li>
                  <li>
                    <p dir="auto">
                      Use the Services in a manner inconsistent with any
                      applicable laws or regulations.
                    </p>
                  </li>
                  <li>
                    <p dir="auto">
                      Engage in unauthorized framing of or linking to the
                      Services.
                    </p>
                  </li>
                  <li>
                    <p dir="auto">
                      Upload or transmit (or attempt to upload or to transmit)
                      viruses, Trojan horses, or other material, including
                      excessive use of capital letters and spamming (continuous
                      posting of repetitive text), that interferes with any
                      party&rsquo;s uninterrupted use and enjoyment of the
                      Services or modifies, impairs, disrupts, alters, or
                      interferes with the use, features, functions, operation,
                      or maintenance of the Services. Engage in any automated
                      use of the system, such as using scripts to send comments
                      or messages, or using any data mining, robots, or similar
                      data gathering and extraction tools.
                    </p>
                  </li>
                  <li>
                    <p dir="auto">
                      Delete the copyright or other proprietary rights notice
                      from any Content.
                    </p>
                  </li>
                  <li>
                    <p dir="auto">
                      Attempt to impersonate another user or person or use the
                      username of another user.
                    </p>
                  </li>
                  <li>
                    <p dir="auto">
                      Upload or transmit (or attempt to upload or to transmit)
                      any material that acts as a passive or active information
                      collection or transmission mechanism, including without
                      limitation, clear graphics interchange formats ("gifs"),
                      1&times;1 pixels, web bugs, cookies, or other similar
                      devices (sometimes referred to as "spyware" or "passive
                      collection mechanisms" or "pcms").
                    </p>
                  </li>
                  <li>
                    <p dir="auto">
                      Interfere with, disrupt, or create an undue burden on the
                      Services or the networks or services connected to the
                      Services.
                    </p>
                  </li>
                  <li>
                    <p dir="auto">
                      Harass, annoy, intimidate, or threaten any of our
                      employees or agents engaged in providing any portion of
                      the Services to you.
                    </p>
                  </li>
                  <li>
                    <p dir="auto">
                      Attempt to bypass any measures of the Services designed to
                      prevent or restrict access to the Services, or any portion
                      of the Services.
                    </p>
                  </li>
                  <li>
                    <p dir="auto">
                      Copy or adapt the Services' software, including but not
                      limited to Flash, PHP, HTML, JavaScript, or other code.
                    </p>
                  </li>
                  <li>
                    <p dir="auto">
                      Except as permitted by applicable law, decipher,
                      decompile, disassemble, or reverse engineer any of the
                      software comprising or in any way making up a part of the
                      Services.
                    </p>
                  </li>
                  <li>
                    <p dir="auto">
                      Except as may be the result of standard search engine or
                      Internet browser usage, use, launch, develop, or
                      distribute any automated system, including without
                      limitation, any spider, robot, cheat utility, scraper, or
                      offline reader that accesses the Services, or use or
                      launch any unauthorized script or other software.
                    </p>
                  </li>
                  <li>
                    <p dir="auto">
                      Use a buying agent or purchasing agent to make purchases
                      on the Services.
                    </p>
                  </li>
                  <li>
                    <p dir="auto">
                      Make any unauthorized use of the Services, including
                      collecting usernames and/or email addresses of users by
                      electronic or other means for the purpose of sending
                      unsolicited email, or creating user accounts by automated
                      means or under false pretenses.
                    </p>
                  </li>
                  <li>
                    <p dir="auto">
                      Use the Services as part of any effort to compete with us
                      or otherwise use the Services and/or the Content for any
                      revenue-generating endeavor or commercial enterprise.
                    </p>
                  </li>
                  <li>
                    <p dir="auto">
                      Use the Services to advertise or offer to sell goods and
                      services.
                    </p>
                  </li>
                </ul>
                <div class="markdown-heading" dir="auto">
                  <h2 class="heading-element" dir="auto" tabindex="-1">
                    7. USER GENERATED CONTRIBUTIONS
                  </h2>
                  <a
                    id="user-content-7-user-generated-contributions"
                    class="anchor"
                    href="https://github.com/ravi-simtel/neonexp-blog/blob/main/src/pages/privacy_policy.md#7-user-generated-contributions"
                  ></a>
                </div>
                <p dir="auto">
                  The Services does not offer users to submit or post content.
                  We may provide you with the opportunity to create, submit,
                  post, display, transmit, perform, publish, distribute, or
                  broadcast content and materials to us or on the Services,
                  including but not limited to text, writings, video, audio,
                  photographs, graphics, comments, suggestions, or personal
                  information or other material (collectively,&nbsp;
                  <strong>"Contributions"</strong>). Contributions may be
                  viewable by other users of the Services and through
                  third-party websites. As such, any Contributions you transmit
                  may be treated in accordance with the Services' Privacy
                  Policy. When you create or make available any Contributions,
                  you thereby represent and warrant that:
                </p>
                <ul dir="auto">
                  <li>
                    <p dir="auto">
                      The creation, distribution, transmission, public display,
                      or performance, and the accessing, downloading, or copying
                      of your Contributions do not and will not infringe the
                      proprietary rights, including but not limited to the
                      copyright, patent, trademark, trade secret, or moral
                      rights of any third party.
                    </p>
                  </li>
                  <li>
                    <p dir="auto">
                      You are the creator and owner of or have the necessary
                      licenses, rights, consents, releases, and permissions to
                      use and to authorize us, the Services, and other users of
                      the Services to use your Contributions in any manner
                      contemplated by the Services and these Legal Terms.
                    </p>
                  </li>
                  <li>
                    <p dir="auto">
                      You have the written consent, release, and/or permission
                      of each and every identifiable individual person in your
                      Contributions to use the name or likeness of each and
                      every such identifiable individual person to enable
                      inclusion and use of your Contributions in any manner
                      contemplated by the Services and these Legal Terms.
                    </p>
                  </li>
                  <li>
                    <p dir="auto">
                      Your Contributions are not false, inaccurate, or
                      misleading. Your Contributions are not unsolicited or
                      unauthorized advertising, promotional materials, pyramid
                      schemes, chain letters, spam, mass mailings, or other
                      forms of solicitation.
                    </p>
                  </li>
                  <li>
                    <p dir="auto">
                      Your Contributions are not obscene, lewd, lascivious,
                      filthy, violent, harassing, libelous, slanderous, or
                      otherwise objectionable (as determined by us).
                    </p>
                  </li>
                  <li>
                    <p dir="auto">
                      Your Contributions do not ridicule, mock, disparage,
                      intimidate, or abuse anyone.
                    </p>
                  </li>
                  <li>
                    <p dir="auto">
                      Your Contributions are not used to harass or threaten (in
                      the legal sense of those terms) any other person and to
                      promote violence against a specific person or class of
                      people.
                    </p>
                  </li>
                  <li>
                    <p dir="auto">
                      Your Contributions do not violate any applicable law,
                      regulation, or rule.
                    </p>
                  </li>
                  <li>
                    <p dir="auto">
                      Your Contributions do not violate the privacy or publicity
                      rights of any third party.
                    </p>
                  </li>
                  <li>
                    <p dir="auto">
                      Your Contributions do not violate any applicable law
                      concerning child pornography, or otherwise intended to
                      protect the health or well-being of minors.
                    </p>
                  </li>
                  <li>
                    <p dir="auto">
                      Your Contributions do not include any offensive comments
                      that are connected to race, national origin, gender,
                      sexual preference, or physical handicap.
                    </p>
                  </li>
                  <li>
                    <p dir="auto">
                      Your Contributions do not otherwise violate, or link to
                      material that violates, any provision of these Legal
                      Terms, or any applicable law or regulation.
                    </p>
                  </li>
                  <li>
                    <p dir="auto">
                      Any use of the Services in violation of the foregoing
                      violates these Legal Terms and may result in, among other
                      things, termination or suspension of your rights to use
                      the Services.
                    </p>
                  </li>
                </ul>
                <div class="markdown-heading" dir="auto">
                  <h2 class="heading-element" dir="auto" tabindex="-1">
                    8. CONTRIBUTION LICENSE
                  </h2>
                  <a
                    id="user-content-8-contribution-license"
                    class="anchor"
                    href="https://github.com/ravi-simtel/neonexp-blog/blob/main/src/pages/privacy_policy.md#8-contribution-license"
                  ></a>
                </div>
                <p dir="auto">
                  You and Services agree that we may access, store, process, and
                  use any information and personal data that you provide
                  following the terms of the Privacy Policy and your choices
                  (including settings).
                </p>
                <p dir="auto">
                  By submitting suggestions or other feedback regarding the
                  Services, you agree that we can use and share such feedback
                  for any purpose without compensation to you.
                </p>
                <p dir="auto">
                  We do not assert any ownership over your Contributions. You
                  retain full ownership of all of your Contributions and any
                  intellectual property rights or other proprietary rights
                  associated with your Contributions. We are not liable for any
                  statements or representations in your Contributions provided
                  by you in any area on the Services. You are solely responsible
                  for your Contributions to the Services and you expressly agree
                  to exonerate us from any and all responsibility and to refrain
                  from any legal action against us regarding your Contributions.
                </p>
                <div class="markdown-heading" dir="auto">
                  <h2 class="heading-element" dir="auto" tabindex="-1">
                    9. SERVICES MANAGEMENT
                  </h2>
                  <a
                    id="user-content-9-services-management"
                    class="anchor"
                    href="https://github.com/ravi-simtel/neonexp-blog/blob/main/src/pages/privacy_policy.md#9-services-management"
                  ></a>
                </div>
                <p dir="auto">
                  We reserve the right, but not the obligation, to:
                </p>
                <p dir="auto">
                  (1) monitor the Services for violations of these Legal Terms;
                </p>
                <p dir="auto">
                  (2) take appropriate legal action against anyone who, in our
                  sole discretion, violates the law or these Legal Terms,
                  including without limitation, reporting such user to law
                  enforcement authorities;
                </p>
                <p dir="auto">
                  (3) in our sole discretion and without limitation, refuse,
                  restrict access to, limit the availability of, or disable (to
                  the extent technologically feasible) any of your Contributions
                  or any portion thereof;
                </p>
                <p dir="auto">
                  (4) in our sole discretion and without limitation, notice, or
                  liability, to remove from the Services or otherwise disable
                  all files and content that are excessive in size or are in any
                  way burdensome to our systems; and
                </p>
                <p dir="auto">
                  (5) otherwise manage the Services in a manner designed to
                  protect our rights and property and to facilitate the proper
                  functioning of the Services.
                </p>
                <div class="markdown-heading" dir="auto">
                  <h2 class="heading-element" dir="auto" tabindex="-1">
                    10. PRIVACY POLICY
                  </h2>
                  <a
                    id="user-content-10-privacy-policy"
                    class="anchor"
                    href="https://github.com/ravi-simtel/neonexp-blog/blob/main/src/pages/privacy_policy.md#10-privacy-policy"
                  ></a>
                </div>
                <p dir="auto">
                  We care about data privacy and security. Please review our
                  Privacy Policy:
                </p>
                <p dir="auto">
                  <a
                    href="https://blog.neonexp.com/privacy_policy"
                    rel="nofollow"
                  >
                    https://blog.neonyte.com/privacy_policy
                  </a>
                </p>
                <p dir="auto">
                  By using the Services, you agree to be bound by our Privacy
                  Policy, which is incorporated into these Legal Terms. Please
                  be advised the Services are hosted in India. If you access the
                  Services from any other region of the world with laws or other
                  requirements governing personal data collection, use, or
                  disclosure that differ from applicable laws in India, then
                  through your continued use of the Services, you are
                  transferring your data to India, and you expressly consent to
                  have your data transferred to and processed in India.
                </p>
                <div class="markdown-heading" dir="auto">
                  <h2 class="heading-element" dir="auto" tabindex="-1">
                    11. TERM AND TERMINATION
                  </h2>
                  <a
                    id="user-content-11-term-and-termination"
                    class="anchor"
                    href="https://github.com/ravi-simtel/neonexp-blog/blob/main/src/pages/privacy_policy.md#11-term-and-termination"
                  ></a>
                </div>
                <div class="snippet-clipboard-content notranslate position-relative overflow-auto">
                  <pre class="notranslate">
                    <code>
                      These Legal Terms shall remain in full force and effect
                      while you use the Services. WITHOUT LIMITING ANY OTHER
                      PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO,
                      IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY,
                      DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING
                      CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR
                      NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY
                      REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE
                      LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY
                      TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR
                      DELETE ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY
                      TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
                    </code>
                  </pre>
                  <div class="zeroclipboard-container">&nbsp;</div>
                </div>
                <p dir="auto">
                  If we terminate or suspend your account for any reason, you
                  are prohibited from registering and creating a new account
                  under your name, a fake or borrowed name, or the name of any
                  third party, even if you may be acting on behalf of the third
                  party. In addition to terminating or suspending your account,
                  we reserve the right to take appropriate legal action,
                  including without limitation pursuing civil, criminal, and
                  injunctive redress.
                </p>
                <div class="markdown-heading" dir="auto">
                  <h2 class="heading-element" dir="auto" tabindex="-1">
                    12. MODIFICATIONS AND INTERRUPTIONS
                  </h2>
                  <a
                    id="user-content-12-modifications-and-interruptions"
                    class="anchor"
                    href="https://github.com/ravi-simtel/neonexp-blog/blob/main/src/pages/privacy_policy.md#12-modifications-and-interruptions"
                  ></a>
                </div>
                <p dir="auto">
                  We reserve the right to change, modify, or remove the contents
                  of the Services at any time or for any reason at our sole
                  discretion without notice. However, we have no obligation to
                  update any information on our Services. We will not be liable
                  to you or any third party for any modification, price change,
                  suspension, or discontinuance of the Services.
                </p>
                <p dir="auto">
                  We cannot guarantee the Services will be available at all
                  times. We may experience hardware, software, or other problems
                  or need to perform maintenance related to the Services,
                  resulting in interruptions, delays, or errors. We reserve the
                  right to change, revise, update, suspend, discontinue, or
                  otherwise modify the Services at any time or for any reason
                  without notice to you. You agree that we have no liability
                  whatsoever for any loss, damage, or inconvenience caused by
                  your inability to access or use the Services during any
                  downtime or discontinuance of the Services. Nothing in these
                  Legal Terms will be construed to obligate us to maintain and
                  support the Services or to supply any corrections, updates, or
                  releases in connection therewith.
                </p>
                <div class="markdown-heading" dir="auto">
                  <h2 class="heading-element" dir="auto" tabindex="-1">
                    13. GOVERNING LAW
                  </h2>
                  <a
                    id="user-content-13-governing-law"
                    class="anchor"
                    href="https://github.com/ravi-simtel/neonexp-blog/blob/main/src/pages/privacy_policy.md#13-governing-law"
                  ></a>
                </div>
                <p dir="auto">
                  These Legal Terms shall be governed by and defined following
                  the laws of India. Simulate Intelligence Private Limited and
                  yourself irrevocably consent that the courts of India shall
                  have exclusive jurisdiction in Bengaluru courts to resolve any
                  dispute which may arise in connection with these Legal Terms.
                </p>
                <div class="markdown-heading" dir="auto">
                  <h2 class="heading-element" dir="auto" tabindex="-1">
                    14. DISPUTE RESOLUTION
                  </h2>
                  <a
                    id="user-content-14-dispute-resolution"
                    class="anchor"
                    href="https://github.com/ravi-simtel/neonexp-blog/blob/main/src/pages/privacy_policy.md#14-dispute-resolution"
                  ></a>
                </div>
                <p dir="auto">
                  You agree to irrevocably submit all disputes related to these
                  Legal Terms or the legal relationship established by these
                  Legal Terms to the jurisdiction of the India courts. Simulate
                  Intelligence Pvt. Ltd. shall also maintain the right to bring
                  proceedings as to the substance of the matter in the courts of
                  the country where you reside or, if these Legal Terms are
                  entered into in the course of your trade or profession, the
                  state of your principal place of business.
                </p>
                <div class="markdown-heading" dir="auto">
                  <h2 class="heading-element" dir="auto" tabindex="-1">
                    15. CORRECTIONS
                  </h2>
                  <a
                    id="user-content-15-corrections"
                    class="anchor"
                    href="https://github.com/ravi-simtel/neonexp-blog/blob/main/src/pages/privacy_policy.md#15-corrections"
                  ></a>
                </div>
                <p dir="auto">
                  There may be information on the Services that contains
                  typographical errors, inaccuracies, or omissions, including
                  descriptions, pricing, availability, and various other
                  information. We reserve the right to correct any errors,
                  inaccuracies, or omissions and to change or update the
                  information on the Services at any time, without prior notice.
                </p>
                <div class="markdown-heading" dir="auto">
                  <h2 class="heading-element" dir="auto" tabindex="-1">
                    16. DISCLAIMER
                  </h2>
                  <a
                    id="user-content-16-disclaimer"
                    class="anchor"
                    href="https://github.com/ravi-simtel/neonexp-blog/blob/main/src/pages/privacy_policy.md#16-disclaimer"
                  ></a>
                </div>
                <div class="snippet-clipboard-content notranslate position-relative overflow-auto">
                  <pre class="notranslate">
                    <code>
                      THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE
                      BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES WILL BE AT
                      YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE
                      DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION
                      WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT
                      LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY,
                      FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE
                      MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY
                      OR COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF
                      ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES
                      AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY
                      (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND
                      MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY
                      NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE
                      OF THE SERVICES, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF
                      OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
                      AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY
                      INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE
                      SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE
                      LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES
                      BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN
                      ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY
                      KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT
                      POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE
                      SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
                      RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR
                      OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY
                      HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION
                      FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL
                      NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR
                      MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY
                      PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF
                      A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY
                      ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND
                      EXERCISE CAUTION WHERE APPROPRIATE.
                    </code>
                  </pre>
                  <div class="zeroclipboard-container">&nbsp;</div>
                </div>
                <div class="markdown-heading" dir="auto">
                  <h2 class="heading-element" dir="auto" tabindex="-1">
                    17. LIMITATIONS OF LIABILITY
                  </h2>
                  <a
                    id="user-content-17-limitations-of-liability"
                    class="anchor"
                    href="https://github.com/ravi-simtel/neonexp-blog/blob/main/src/pages/privacy_policy.md#17-limitations-of-liability"
                  ></a>
                </div>
                <div class="snippet-clipboard-content notranslate position-relative overflow-auto">
                  <pre class="notranslate">
                    <code>
                      IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS
                      BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT,
                      INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL,
                      OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE,
                      LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF
                      THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE
                      POSSIBILITY OF SUCH DAMAGES.
                    </code>
                  </pre>
                  <div class="zeroclipboard-container">&nbsp;</div>
                </div>
                <div class="markdown-heading" dir="auto">
                  <h2 class="heading-element" dir="auto" tabindex="-1">
                    18. INDEMNIFICATION
                  </h2>
                  <a
                    id="user-content-18-indemnification"
                    class="anchor"
                    href="https://github.com/ravi-simtel/neonexp-blog/blob/main/src/pages/privacy_policy.md#18-indemnification"
                  ></a>
                </div>
                <p dir="auto">
                  You agree to defend, indemnify, and hold us harmless,
                  including our subsidiaries, affiliates, and all of our
                  respective officers, agents, partners, and employees, from and
                  against any loss, damage, liability, claim, or demand,
                  including reasonable attorneys&rsquo; fees and expenses, made
                  by any third party due to or arising out of: (1) use of the
                  Services;
                </p>
                <p dir="auto">(2) breach of these Legal Terms;</p>
                <p dir="auto">
                  (3) any breach of your representations and warranties set
                  forth in these Legal Terms;
                </p>
                <p dir="auto">
                  (4) your violation of the rights of a third party, including
                  but not limited to intellectual property rights; or
                </p>
                <p dir="auto">
                  (5) any overt harmful act toward any other user of the
                  Services with whom you connected via the Services.
                </p>
                <p dir="auto">
                  Notwithstanding the foregoing, we reserve the right, at your
                  expense, to assume the exclusive defense and control of any
                  matter for which you are required to indemnify us, and you
                  agree to cooperate, at your expense, with our defense of such
                  claims. We will use reasonable efforts to notify you of any
                  such claim, action, or proc eeding which is subject to this
                  indemnification upon becoming aware of it.
                </p>
                <div class="markdown-heading" dir="auto">
                  <h2 class="heading-element" dir="auto" tabindex="-1">
                    19. USER DATA
                  </h2>
                  <a
                    id="user-content-19-user-data"
                    class="anchor"
                    href="https://github.com/ravi-simtel/neonexp-blog/blob/main/src/pages/privacy_policy.md#19-user-data"
                  ></a>
                </div>
                <p dir="auto">
                  We will maintain certain data that you transmit to the
                  Services for the purpose of managing the performance of the
                  Services, as well as data relating to your use of the
                  Services. Although we perform regular routine backups of data,
                  you are solely responsible for all data that you transmit or
                  that relates to any activity you have undertaken using the
                  Services. You agree that we shall have no liability to you for
                  any loss or corruption of any such data, and you hereby waive
                  any right of action against us arising from any such loss or
                  corruption of such data.
                </p>
                <div class="markdown-heading" dir="auto">
                  <h2 class="heading-element" dir="auto" tabindex="-1">
                    20. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
                  </h2>
                  <a
                    id="user-content-20-electronic-communications-transactions-and-signatures"
                    class="anchor"
                    href="https://github.com/ravi-simtel/neonexp-blog/blob/main/src/pages/privacy_policy.md#20-electronic-communications-transactions-and-signatures"
                  ></a>
                </div>
                <p dir="auto">
                  Visiting the Services, sending us emails, and completing
                  online forms constitute electronic communications. You consent
                  to receive electronic communications, and you agree that all
                  agreements, notices, disclosures, and other communications we
                  provide to you electronically, via email and on the Services,
                  satisfy any legal requirement that such communication be in
                  writing.
                </p>
                <div class="snippet-clipboard-content notranslate position-relative overflow-auto">
                  <pre class="notranslate">
                    <code>
                      YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES,
                      CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC
                      DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS
                      INITIATED OR COMPLETED BY US OR VIA THE SERVICES.
                    </code>
                  </pre>
                  <div class="zeroclipboard-container">&nbsp;</div>
                </div>
                <p dir="auto">
                  You hereby waive any rights or requirements under any
                  statutes, regulations, rules, ordinances, or other laws in any
                  jurisdiction which require an original signature or delivery
                  or retention of non-electronic records, or to payments or the
                  granting of credits by any means other than electronic means.
                </p>
                <div class="markdown-heading" dir="auto">
                  <h2 class="heading-element" dir="auto" tabindex="-1">
                    21. MISCELLANEOUS
                  </h2>
                  <a
                    id="user-content-21-miscellaneous"
                    class="anchor"
                    href="https://github.com/ravi-simtel/neonexp-blog/blob/main/src/pages/privacy_policy.md#21-miscellaneous"
                  ></a>
                </div>
                <p dir="auto">
                  These Legal Terms and any policies or operating rules posted
                  by us on the Services or in respect to the Services constitute
                  the entire agreement and understanding between you and us. Our
                  failure to exercise or enforce any right or provision of these
                  Legal Terms shall not operate as a waiver of such right or
                  provision. These Legal Terms operate to the fullest extent
                  permissible by law. We may assign any or all of our rights and
                  obligations to others at any time. We shall not be responsible
                  or liable for any loss, damage, delay, or failure to act
                  caused by any cause beyond our reasonable control. If any
                  provision or part of a provision of these Legal Terms is
                  determined to be unlawful, void, or unenforceable, that
                  provision or part of the provision is deemed severable from
                  these Legal Terms and does not affect the validity and
                  enforceability of any remaining provisions. There is no joint
                  venture, partnership, employment or agency relationship
                  created between you and us as a result of these Legal Terms or
                  use of the Services. You agree that these Legal Terms will not
                  be construed against us by virtue of having drafted them. You
                  hereby waive any and all defenses you may have based on the
                  electronic form of these Legal Terms and the lack of signing
                  by the parties hereto to execute these Legal Terms.
                </p>
                <div class="markdown-heading" dir="auto">
                  <h2 class="heading-element" dir="auto" tabindex="-1">
                    22. CONTACT US
                  </h2>
                  <a
                    id="user-content-22-contact-us"
                    class="anchor"
                    href="https://github.com/ravi-simtel/neonexp-blog/blob/main/src/pages/privacy_policy.md#22-contact-us"
                  ></a>
                </div>
                <p dir="auto">
                  In order to resolve a complaint regarding the Services or to
                  receive further information regarding use of the Services,
                  please contact us at:
                </p>
                <div class="snippet-clipboard-content notranslate position-relative overflow-auto">
                  <pre class="notranslate">
                    <code>
                      Simulate Intelligence Private Limited 36 Emami Nest, Flat
                      No. 302, 2nd Floor 8th Main 16th Cross Malleswaram
                      Bengaluru, KA 560055 India Phone: 91 95133 33471 email:
                      support@neonyte.com
                    </code>
                  </pre>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default Privacy;
