import { Outlet } from "react-router-dom";
import { SearchContextProvider } from "../context/searchContext";

const SearchContextLayout = () => {
  return (
    <SearchContextProvider>
      <Outlet />
    </SearchContextProvider>
  );
};

export default SearchContextLayout;
