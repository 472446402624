export const PRODUCT_SUBCATEGORY = {
  Electronics: [
    {
      key: "Mobile Phone",
      value: "Mobile Phone",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl:
        process.env.REACT_APP_PUBLIC_URL +
        "/assets/img/categories/top_categories/electronics/mobile_phone.gif",
      enable: true
    },
    {
      key: "Smart Watch",
      value: "Smart Watch",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl:
        process.env.REACT_APP_PUBLIC_URL +
        "/assets/img/categories/top_categories/electronics/smart_watch.gif",
      enable: true
    },
    {
      key: "Headset",
      value: "Headset",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl:
        process.env.REACT_APP_PUBLIC_URL +
        "/assets/img/categories/top_categories/electronics/headset.gif",
      enable: true
    },
    {
      key: "Laptop",
      value: "Laptop",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl:
        process.env.REACT_APP_PUBLIC_URL +
        "/assets/img/categories/top_categories/electronics/laptop.gif",
      enable: true
    },
    {
      key: "Desktop",
      value: "Desktop",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl:
        process.env.REACT_APP_PUBLIC_URL +
        "/assets/img/categories/top_categories/electronics/desktop.gif",
      enable: true
    },
    {
      key: "Tablet",
      value: "Tablet",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl:
        process.env.REACT_APP_PUBLIC_URL +
        "/assets/img/categories/top_categories/electronics/tablet.gif",
      enable: true
    },
    {
      key: "Keyboard",
      value: "Keyboard",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl:
        process.env.REACT_APP_PUBLIC_URL +
        "/assets/img/categories/top_categories/electronics/keyboard.gif",
      enable: true
    },
    {
      key: "Monitor",
      value: "Monitor",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl:
        process.env.REACT_APP_PUBLIC_URL +
        "/assets/img/categories/top_categories/electronics/monitor.gif"
    },
    {
      key: "Mouse",
      value: "Mouse",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl:
        process.env.REACT_APP_PUBLIC_URL +
        "/assets/img/categories/top_categories/electronics/mouse.png"
    },
    {
      key: "Power Bank",
      value: "Power Bank",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl:
        process.env.REACT_APP_PUBLIC_URL +
        "/assets/img/categories/top_categories/electronics/powerbank.png"
    }
  ],
  Appliances: [
    {
      value: "Audio",
      key: "Audio",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: `https://reference-buyer-app-assets.s3-ap-south-1.amazonaws.com/public-assets/Electronics/Audio.png`
    },
    {
      value: "Camera and Camcorder",
      key: "Camera and Camcorder",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: `https://reference-buyer-app-assets.s3-ap-south-1.amazonaws.com/public-assets/Electronics/Camera and Camcorder.png`
    },
    {
      value: "Safety Security",
      key: "Safety Security",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: `https://reference-buyer-app-assets.s3-ap-south-1.amazonaws.com/public-assets/Electronics/Safety Security.png`
    },
    {
      value: "Speaker",
      key: "Speaker",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: `https://reference-buyer-app-assets.s3-ap-south-1.amazonaws.com/public-assets/Electronics/Speaker.png`
    },
    {
      value: "Television",
      key: "Television",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: `https://reference-buyer-app-assets.s3-ap-south-1.amazonaws.com/public-assets/Electronics/Television.png`
    },
    {
      value: "Video",
      key: "Video",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: `https://reference-buyer-app-assets.s3-ap-south-1.amazonaws.com/public-assets/Electronics/Video.png`
    },
    {
      value: "Air Conditioning and Air Cleaners",
      key: "Air Conditioning and Air Cleaners",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: `https://reference-buyer-app-assets.s3-ap-south-1.amazonaws.com/public-assets/Electronics/Air Conditioning and Air Cleaners.png`
    },
    {
      value: "Health, Home and Personal Care",
      key: "Health, Home and Personal Care",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: `https://reference-buyer-app-assets.s3-ap-south-1.amazonaws.com/public-assets/Electronics/Health, Home and Personal Care.png`
    },
    {
      value: "Heaters",
      key: "Heaters",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: `https://reference-buyer-app-assets.s3-ap-south-1.amazonaws.com/public-assets/Electronics/Heaters.png`
    },
    {
      value: "Kitchen Appliances",
      key: "Kitchen Appliances",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: `https://reference-buyer-app-assets.s3-ap-south-1.amazonaws.com/public-assets/Electronics/Kitchen Appliances.png`
    },
    {
      value: "Lighting & Electric Fans",
      key: "Lighting & Electric Fans",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: `https://reference-buyer-app-assets.s3-ap-south-1.amazonaws.com/public-assets/Electronics/Lighting & Electric Fans.png`
    },
    {
      value: "Refrigerators and Freezers",
      key: "Refrigerators and Freezers",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: `https://reference-buyer-app-assets.s3-ap-south-1.amazonaws.com/public-assets/Electronics/Refrigerators and Freezers.png`
    },
    {
      value: "Vacuum Cleaners",
      key: "Vacuum Cleaners",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: `https://reference-buyer-app-assets.s3-ap-south-1.amazonaws.com/public-assets/Electronics/Vacuum Cleaners.png`
    },
    {
      value: "Washing Machines and Accessories",
      key: "Washing Machines and Accessories",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: `https://reference-buyer-app-assets.s3-ap-south-1.amazonaws.com/public-assets/Electronics/Washing Machines and Accessories.png`
    },
    {
      value: "Water Purifiers and Coolers",
      key: "Water Purifiers and Coolers",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: `https://reference-buyer-app-assets.s3-ap-south-1.amazonaws.com/public-assets/Electronics/Water Purifiers and Coolers.png`
    },
    {
      value: "Inverter & Stabilizer",
      key: "Inverter & Stabilizer",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: `https://reference-buyer-app-assets.s3-ap-south-1.amazonaws.com/public-assets/Electronics/Inverter & Stabilizer.png`
    }
  ],
  Fashion: [
    {
      value: "Shirts",
      key: "Shirts",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "T Shirts",
      key: "T Shirts",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Sweatshirts",
      key: "Sweatshirts",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Kurtas & Kurta Sets",
      key: "Kurtas & Kurta Sets",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Jackets & Coats",
      key: "Jackets & Coats",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Sweaters",
      key: "Sweaters",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Suits",
      key: "Suits",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Sherwanis",
      key: "Sherwanis",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Track Shirts",
      key: "Track Shirts",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Track Suits",
      key: "Track Suits",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Unstitched Fabrics",
      key: "Unstitched Fabrics",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Dresses",
      key: "Dresses",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Tops",
      key: "Tops",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Trousers",
      key: "Trousers",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Capris",
      key: "Capris",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Coordinates",
      key: "Coordinates",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Playsuits",
      key: "Playsuits",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Jumpsuits",
      key: "Jumpsuits",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Shrugs & Blouses",
      key: "Shrugs & Blouses",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Blazers & Waistcoats",
      key: "Blazers & Waistcoats",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Tights, Leggings & Jeggings",
      key: "Tights, Leggings & Jeggings",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Track Pants",
      key: "Track Pants",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Jeans",
      key: "Jeans",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Shorts",
      key: "Shorts",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Joggers",
      key: "Joggers",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Dhotis & Dhoti Pants",
      key: "Dhotis & Dhoti Pants",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Churidars",
      key: "Churidars",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Salwars",
      key: "Salwars",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Dungarees & Jumpsuits",
      key: "Dungarees & Jumpsuits",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Skirts",
      key: "Skirts",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Clothing Sets",
      key: "Clothing Sets",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Belts",
      key: "Belts",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Caps & Hats",
      key: "Caps & Hats",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Kurtis, Tunics",
      key: "Kurtis, Tunics",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Sarees",
      key: "Sarees",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Ethnic Wear",
      key: "Ethnic Wear",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Palazzos",
      key: "Palazzos",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Dress Materials",
      key: "Dress Materials",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Lehenga Cholis",
      key: "Lehenga Cholis",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Dupattas & Shawls",
      key: "Dupattas & Shawls",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Burqas & Hijabs",
      key: "Burqas & Hijabs",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Blouses",
      key: "Blouses",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Blouse Pieces",
      key: "Blouse Pieces",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Briefs",
      key: "Briefs",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Boxers",
      key: "Boxers",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Vests",
      key: "Vests",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Robes",
      key: "Robes",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Night Suits",
      key: "Night Suits",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Thermal Wear",
      key: "Thermal Wear",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Swim Bottoms",
      key: "Swim Bottoms",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Swimwear",
      key: "Swimwear",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Bra",
      key: "Bra",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Shapewear",
      key: "Shapewear",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Sleepwear & Loungewear",
      key: "Sleepwear & Loungewear",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Camisoles",
      key: "Camisoles",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Lingerie Sets & Accessories",
      key: "Lingerie Sets & Accessories",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Bath Robes",
      key: "Bath Robes",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Towels",
      key: "Towels",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Pyjamas",
      key: "Pyjamas",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Party Wear",
      key: "Party Wear",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Innerwear & Sleepwear",
      key: "Innerwear & Sleepwear",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Nightwear & Loungewear",
      key: "Nightwear & Loungewear",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Watches",
      key: "Watches",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Gloves",
      key: "Gloves",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Socks",
      key: "Socks",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Stockings",
      key: "Stockings",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Laces",
      key: "Laces",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Soles & Charms",
      key: "Soles & Charms",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Shoe Racks & Organisers",
      key: "Shoe Racks & Organisers",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Shoe Care - Accessories",
      key: "Shoe Care - Accessories",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Flip-Flops & Flats",
      key: "Flip-Flops & Flats",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Sandals & Floaters",
      key: "Sandals & Floaters",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Backpacks",
      key: "Backpacks",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Handbags",
      key: "Handbags",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Trolley, Luggage & Suitcases",
      key: "Trolley, Luggage & Suitcases",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Formal Shoes",
      key: "Formal Shoes",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Casual Shoes",
      key: "Casual Shoes",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Sports Shoes",
      key: "Sports Shoes",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Outdoor Shoes",
      key: "Outdoor Shoes",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Work & Safety Shoes",
      key: "Work & Safety Shoes",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Ethnic Shoes",
      key: "Ethnic Shoes",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Boots",
      key: "Boots",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Heels",
      key: "Heels",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Contact Lenses",
      key: "Contact Lenses",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Eye Glasses",
      key: "Eye Glasses",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Eye Glass Frames",
      key: "Eye Glass Frames",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Sunglasses",
      key: "Sunglasses",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Contact Lens Cases",
      key: "Contact Lens Cases",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Contact Lens Solutions",
      key: "Contact Lens Solutions",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Contact Lens Tweezers",
      key: "Contact Lens Tweezers",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Eyeglasses Pouches & Cases",
      key: "Eyeglasses Pouches & Cases",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Microfiber Wipes",
      key: "Microfiber Wipes",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Eyewear Slings",
      key: "Eyewear Slings",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Bracelets",
      key: "Bracelets",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Chains",
      key: "Chains",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Mangalsutra",
      key: "Mangalsutra",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Anklets",
      key: "Anklets",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Bangles & Bracelets",
      key: "Bangles & Bracelets",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Necklaces",
      key: "Necklaces",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Earrings",
      key: "Earrings",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Jewellery Sets",
      key: "Jewellery Sets",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Nosepins & Noserings",
      key: "Nosepins & Noserings",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Pendants",
      key: "Pendants",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Rings",
      key: "Rings",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Toe Rings",
      key: "Toe Rings",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Gold Coins",
      key: "Gold Coins",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Brooch",
      key: "Brooch",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    }
  ],
  "Beauty & Personal Care": [
    {
      value: "Fragrance",
      key: "Fragrance",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: `https://reference-buyer-app-assets.s3-ap-south-1.amazonaws.com/public-assets/Beauty & Personal Care/Fragrance.png`
    },
    {
      value: "Bath Soaps and Gels",
      key: "Bath Soaps and Gels",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Hair Oils, Care, and Styling",
      key: "Hair Oils, Care, and Styling",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Shampoos and Conditioners",
      key: "Shampoos and Conditioners",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Shaving and Grooming",
      key: "Shaving and Grooming",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Beard Care and Tools",
      key: "Beard Care and Tools",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Grooming Tools and Accessories",
      key: "Grooming Tools and Accessories",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Makeup - Nail Care",
      key: "Makeup - Nail Care",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Makeup - Eyes",
      key: "Makeup - Eyes",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Makeup - Face",
      key: "Makeup - Face",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Makeup - Lips",
      key: "Makeup - Lips",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Makeup - Body",
      key: "Makeup - Body",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Makeup - Remover",
      key: "Makeup - Remover",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Makeup - Sets and Kits",
      key: "Makeup - Sets and Kits",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Makeup - Tools and Brushes",
      key: "Makeup - Tools and Brushes",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Makeup - Kits and Combos",
      key: "Makeup - Kits and Combos",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Skin Care - Face Cleansers",
      key: "Skin Care - Face Cleansers",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Skin Care - Hand and Feet",
      key: "Skin Care - Hand and Feet",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Body Care - Cleansers",
      key: "Body Care - Cleansers",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Body Care - Moisturizers",
      key: "Body Care - Moisturizers",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Body Care - Loofah and Other Tools",
      key: "Body Care - Loofah and Other Tools",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Body Care - Bath Salt and Additives",
      key: "Body Care - Bath Salt and Additives",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Hair Care - Shampoo, Oils, Conditioners",
      key: "Hair Care - Shampoo, Oils, Conditioners",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Skin Care - Lotions, Moisturisers, and Creams",
      key: "Skin Care - Lotions, Moisturisers, and Creams",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Skin Care - Oils and Serums",
      key: "Skin Care - Oils and Serums",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    },
    {
      value: "Gift Voucher",
      key: "Gift Voucher",
      protocolKey: "@ondc/org/statutory_reqs_packaged_commodities",
      imageUrl: ``
    }
  ]
};
