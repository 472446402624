import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";
import React from "react";
import Box from "@mui/material/Box";

const ShopCategoryList = ({ category_type, category, categories }) => {
  const navigate = useNavigate();
  const locationData = useLocation();

  const useQuery = () => {
    const { search } = locationData;
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let query = useQuery();
  if (category === "") category = "Electronics";
  console.log("category...", category);

  const updateCategoryParams = (catName) => {
    if (
      (locationData.search === "" && query.get("c") !== null) ||
      query.get("c") === false
    ) {
      navigate(`/application/products`);
    } else {
      const params = new URLSearchParams({});
      params.set("c", catName);
      navigate(
        {
          pathname: locationData.pathname,
          search: params.toString()
        },
        { replace: true }
      );
    }
  };

  const updateSubCategoryParams = (catName, subCatName) => {
    const params = new URLSearchParams({});
    params.set("c", catName);
    params.set("sc", subCatName);
    navigate({
      pathname: `/application/products`,
      search: params.toString()
    });
  };

  return (
    <div className="sidebar-widget">
      <h4 className="pro-sidebar-title">{category} </h4>

      <div className="sidebar-widget-list mt-30 ">
        <Box
          style={{
            overflowY: "auto",
            maxWidth: "300px",
            maxHeight: "600px",

            display: "flex",
            flexGrow: 1,
            flexDirection: "column"
          }}
        >
          {categories ? (
            <ul>
              <li>
                <div className="sidebar-widget-list-left">
                  <button
                    onClick={(e) => {
                      navigate(`/application/products`);
                    }}
                  >
                    <span className="checkmark" /> All Categories
                  </button>
                </div>
              </li>
              {categories.map((item, key) => {
                console.log("key:", key);
                return (
                  <li key={key}>
                    <div className="sidebar-widget-list-left">
                      <button
                        onClick={(e) => {
                          if (category_type === "category") {
                            updateCategoryParams(e.currentTarget.innerText);
                          }
                          if (category_type === "sub_category") {
                            updateSubCategoryParams(category, item);
                          }
                        }}
                      >
                        <span className="checkmark" /> {item}
                      </button>
                    </div>
                  </li>
                );
              })}
            </ul>
          ) : (
            "No categories found"
          )}
        </Box>
      </div>
    </div>
  );
};

ShopCategoryList.propTypes = {
  categories: PropTypes.array
};

export default ShopCategoryList;
