import { Fragment, useRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import GoogleMap from "../../components/google-map";
import FormData from "form-data";
import axios from "axios";
const Contact = () => {
  const [showModal, setShowModal] = useState(false);

  let { pathname } = useLocation();
  const form = useRef();
  const modalDisplayTime = 2000; // 1 second

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(form.current);

    // convert FormData to simple object, which can be serialized to JSON
    let payload = {};
    formData.forEach((value, key) => (payload[key] = value));
    console.log("payload:", payload);
    try {
      let url = "https://preprod.neonexp.com/clientApis/v2/customerService";
      axios
        .post(url, payload)
        .then((response) => console.log(response))
        .catch((error) => console.error(error));

      e.target.reset();
      setShowModal(true);
    } catch {
      console.error(e);
    }
  };

  useEffect(() => {
    let timeout;
    if (showModal) {
      timeout = setTimeout(() => {
        setShowModal(false);
      }, modalDisplayTime);
    }
    return () => clearTimeout(timeout);
  }, [showModal]);

  return (
    <Fragment>
      <SEO
        titleTemplate="Contact"
        description="Contact page of Neonyte Retail Marketplace"
      />
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb
          pages={[
            { label: "Home", path: "/" },
            {
              label: "Contact",
              path: pathname
            }
          ]}
        />

        <div className="contact-area pt-100 pb-100">
          <div className="container">
            <div className="custom-row-2">
              <div className="col-12 col-lg-4 col-md-5">
                <div className="contact-info-wrap">
                  <div className="single-contact-info">
                    <div className="contact-icon">
                      <i className="fa fa-phone" />
                    </div>
                    <div className="contact-info-dec">
                      <p>+091 95133 33471</p>
                      <p>+091 95133 33472</p>
                    </div>
                  </div>
                  <div className="single-contact-info">
                    <div className="contact-icon">
                      <i className="fa fa-globe" />
                    </div>
                    <div className="contact-info-dec">
                      <p>
                        <a href="info@simtel.ai">info@simtel.ai</a>
                      </p>
                      <p>
                        <a href="https://simtel.ai">https://simtel.ai</a>
                      </p>
                    </div>
                  </div>
                  <div className="single-contact-info">
                    <div className="contact-icon">
                      <i className="fa fa-map-marker" />
                    </div>
                    <div className="contact-info-dec">
                      <p>No. 36 Emami Nest Flat No 302 </p>
                      <p>8th Main 16th Cross Malleshwaram</p>
                    </div>
                  </div>
                  <div className="contact-social text-center">
                    <h3>Follow Us</h3>
                    <ul>
                      <li>
                        <a href="//facebook.com">
                          <i className="fa fa-facebook" />
                        </a>
                      </li>
                      <li>
                        <a href="//pinterest.com">
                          <i className="fa fa-pinterest-p" />
                        </a>
                      </li>
                      <li>
                        <a href="//thumblr.com">
                          <i className="fa fa-tumblr" />
                        </a>
                      </li>
                      <li>
                        <a href="//vimeo.com">
                          <i className="fa fa-vimeo" />
                        </a>
                      </li>
                      <li>
                        <a href="//twitter.com">
                          <i className="fa fa-twitter" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-8 col-md-7">
                <div className="contact-form">
                  <div className="contact-title mb-30">
                    <h2>Get In Touch</h2>
                  </div>
                  <form
                    onSubmit={handleSubmit}
                    ref={form}
                    className="contact-form-style"
                  >
                    <div className="row">
                      <div className="col-lg-6">
                        <input name="name" placeholder="Name*" type="text" />
                      </div>
                      <div className="col-lg-6">
                        <input name="email" placeholder="Email*" type="email" />
                      </div>
                      <div className="col-lg-12">
                        <input
                          name="subject"
                          placeholder="Subject*"
                          type="text"
                        />
                      </div>
                      <div className="col-lg-12">
                        <textarea
                          name="message"
                          placeholder="Your Message*"
                          defaultValue={""}
                        />
                        <button className="submit" type="submit">
                          SEND
                        </button>
                        {showModal && <div>Submitted, Thank you!</div>}
                      </div>
                    </div>
                  </form>
                  <p className="form-message" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default Contact;
