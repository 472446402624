import React, { useEffect, useState } from "react";

import SubCategoriesComponent from "../product/subCategories/subCategoriesSwipes";
import SubCategoriesHeader from "../product/subCategories/subCategories";
import SubCategories from "../category/subCategories/subCategories";
import CategoryBrands from "../category/brands/brands";
import ProductList from "../product/productList/productList";
import useStyles from "./style";
import ShopSideTab from "../../wrappers/product/ShopSideTab";
import categoriesData from "../../components/appLayout/navbar/categories.json";
import { useLocation } from "react-router-dom";
import { PRODUCT_SUBCATEGORY } from "../../constants/categories";

const Products = () => {
  const locationData = useLocation();
  const classes = useStyles();

  const useQuery = () => {
    const { search } = locationData;
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };
  let query = useQuery();
  const [isCatAvailable, setIsCatAvailable] = useState(false);
  const [isSubCatAvailable, setIsSubCatAvailable] = useState(false);
  const [isSearchAvailable, setIsSearchAvailable] = useState(false);
  const [category, setCategory] = useState(false);
  const [subCategory, setSubCategory] = useState(false);

  var default_categories = [];
  var default_sub_categories = [];

  default_categories = categoriesData.map((item) => {
    return item.category;
  });

  useEffect(() => {
    if (locationData) {
      const cat = query.get("c");
      const subCat = query.get("sc");
      const product = query.get("s");

      if (cat) {
        setIsCatAvailable(true);
        setCategory(cat);
        console.log("default_sub_categories", default_sub_categories);
      } else {
        setIsCatAvailable(false);
      }
      if (cat && subCat) {
        setIsSubCatAvailable(true);
        setSubCategory(subCat);

        // need tags and other filters
      } else {
        setIsSubCatAvailable(false);
      }
      if (product) {
        setIsSearchAvailable(true);
      } else {
        setIsSearchAvailable(false);
      }
    }
  }, [locationData, category]);

  if (isSearchAvailable && isSubCatAvailable && isCatAvailable) {
    return (
      <>
        <div className="fixed-side-bar pt-95 pb-100 ps-20">
          <ShopSideTab
            category_type={"category"}
            category={category}
            categories={default_categories}
            sideSpaceClass="mr-30"
          />
        </div>
        <div className="container">
          <div className="row">
            <div className={classes.homeContainer}>
              <SubCategoriesComponent />
              <ProductList />
            </div>
          </div>
        </div>
      </>
    );
  } else if (!isSearchAvailable && isSubCatAvailable && isCatAvailable) {
    return (
      <>
        <div className="fixed-side-bar pt-95 pb-100 ps-20">
          <ShopSideTab
            category_type={"category"}
            category={category}
            categories={default_categories}
            sideSpaceClass="mr-30"
          />
        </div>
        <div className="container">
          <div className="row">
            <div className={classes.homeContainer}>
              <SubCategoriesComponent />
              <ProductList />
            </div>
          </div>
        </div>
      </>
    );
  } else if (!isSearchAvailable && !isSubCatAvailable && isCatAvailable) {
    return (
      <>
        <div className="fixed-side-bar pt-95 pb-100 ps-20">
          <ShopSideTab
            category_type={"sub_category"}
            category={category}
            categories={PRODUCT_SUBCATEGORY[category].map((item) => {
              return item.key;
            })}
            sideSpaceClass="mr-30"
          />
        </div>
        <div className="container">
          <div className="row">
            <SubCategoriesComponent />
            <ProductList />
            <CategoryBrands />
          </div>
        </div>
      </>
    );
  } else if (isSearchAvailable && !isSubCatAvailable && !isCatAvailable) {
    return (
      <>
        <div className="fixed-side-bar pt-95 pb-100 ps-20">
          <ShopSideTab
            category_type={"category"}
            category={category}
            categories={default_categories}
            sideSpaceClass="mr-30"
          />
        </div>
        <div className=" container">
          <div className="row">
            <div className={classes.homeContainer}>
              <ProductList />
            </div>
          </div>
        </div>
      </>
    );
  } else if (isSearchAvailable && !isSubCatAvailable && isCatAvailable) {
    return (
      <>
        <div className="fixed-side-bar pt-95 pb-100 ps-20">
          <ShopSideTab
            category_type={"category"}
            category={category}
            categories={default_categories}
            sideSpaceClass="mr-30"
          />
        </div>
        <div className="container">
          <div className="row">
            <div className={classes.homeContainer}>
              <ProductList />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="fixed-side-bar pt-95 pb-100 ps-20">
          <ShopSideTab
            category_type={"category"}
            category={category}
            categories={default_categories}
            sideSpaceClass="mr-30"
          />
        </div>
        <div className="container">
          <div className="row">
            <div className={classes.homeContainer}>
              <ProductList />
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default Products;
