import React, { useContext, useEffect } from "react";
import { AddressContext } from "../../../context/addressContext";
import { useRazorpay, RazorpayOrderOptions } from "react-razorpay";

const Razorpay = (props) => {
  const { error, isLoading, Razorpay } = useRazorpay();

  const {
    paymentKey,
    paymentParams,
    setPaymentStatus,
    setPaymentResponse,
    setDisplayRazorPay,
    providerName
  } = props;
  const { billingAddress } = useContext(AddressContext);

  const addressFields = [
    billingAddress.address.door,
    billingAddress.address.building,
    billingAddress.address.street,
    billingAddress.address.areaCode,
    billingAddress.address.city,
    billingAddress.address.state,
    billingAddress.address.country
  ];

  const commaSeparatedAddress = addressFields
    .filter((field) => field !== undefined && field !== null)
    .join(", ");

  const initializeRazorpay = () => {
    console.log("paymentParams", paymentParams);

    const options = {
      key: `${paymentKey}`,
      amount: `${paymentParams.intentTransaction.amount}`,
      currency: "INR",
      name: "Neonyte Retail Marketplace",
      description: `${providerName}`,
      image: `https://simtel-ondc-storage.s3.us-east-1.amazonaws.com/website/assets/img/logo/logo.png`,
      order_id: `${paymentParams.orderDetail.id}`,
      handler: function (response) {
        console.log("payment success response: ", response);
        setPaymentResponse(response);
        setPaymentStatus("success");
        setDisplayRazorPay(false);
      },
      prefill: {
        name: `${billingAddress.name}`,
        email: `${billingAddress.email}`,
        contact: `${billingAddress.phone}`
      },
      notes: {
        address: commaSeparatedAddress
      },
      theme: {
        color: "#E75627"
      },
      redirect: false,
      modal: {
        escape: true,
        ondismiss: function () {
          console.log("closing window");
        }
      }
    };
    console.log("Options", options);

    const rzp = new Razorpay(options);
    rzp.open();

    rzp.on("payment.failed", function (response) {
      console.log("payment failure response: ", response);
      setPaymentResponse(response);
      setPaymentStatus("fail");
    });
  };
  return <>{initializeRazorpay}</>;
};

export default Razorpay;
