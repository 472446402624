import { Outlet } from "react-router-dom";
import { CartContextProvider } from "../context/cartContext";

const CartContextLayout = () => {
  return (
    <CartContextProvider>
      <Outlet />
    </CartContextProvider>
  );
};

export default CartContextLayout;
