import { Outlet } from "react-router-dom";
import { AddressContextProvider } from "../context/addressContext";

const AddressContextLayout = () => {
  return (
    <AddressContextProvider>
      <Outlet />
    </AddressContextProvider>
  );
};

export default AddressContextLayout;
