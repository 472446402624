// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.globalStyles_background__iRmc6 {
  min-height: 100vh;
  height: 100vh;
  overflow: auto;
  background-color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/styles/globalStyles.module.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;EACA,aAAA;EACA,cAAA;EACA,sBAAA;AADF","sourcesContent":["@import \"../assets/scss/variables\";\n\n.background {\n  min-height: 100vh;\n  height: 100vh;\n  overflow: auto;\n  background-color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": `globalStyles_background__iRmc6`
};
export default ___CSS_LOADER_EXPORT___;
