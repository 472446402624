import PropTypes from "prop-types";
import { Fragment } from "react";
import FooterOne from "../wrappers/footer/FooterOne";
import ScrollToTop from "../components/scroll-to-top";
import Navbar from "../components/appLayout/navbar/navbar";

const LayoutOne = ({
  children,
  headerContainerClass,
  headerTop,
  headerPaddingClass,
  headerPositionClass,
  isCheckout = false
}) => {
  return (
    <Fragment>
      <Navbar isCheckout={isCheckout} />
      {children}
      <FooterOne
        backgroundColorClass="bg-[#fdece8]"
        spaceTopClass="pt-100"
        spaceBottomClass="pb-70"
      />
      <ScrollToTop />
    </Fragment>
  );
};

LayoutOne.propTypes = {
  children: PropTypes.node,
  headerContainerClass: PropTypes.string,
  headerPaddingClass: PropTypes.string,
  headerPositionClass: PropTypes.string,
  headerTop: PropTypes.string
};

export default LayoutOne;
