import { makeStyles } from "@mui/styles";
import palette from "../../../utils/Theme/palette";

const useStyles = makeStyles({
  brandContainer: {
    marginTop: "56px !important",
    padding: "25px 54px 39px 54px !important"
  },
  brandItemContainer: {
    cursor: "pointer"
  },
  brandCard: {
    boxShadow: "none !important",
    background: "#F5F5F5 !important",
    width: "128px",
    height: "128px",
    justifyContent: "center",
    display: "flex"
  },
  brandImage: {
    margin: "auto !important",
    objectFit: "contain",
    display: "flex",
    justifyContent: "center"
  },
  brandNameTypo: {
    fontWeight: "600 !important",
    marginTop: "13px !important",
    textAlign: "center",
    paddingLeft: "10px",
    paddingRight: "10px"
  }
});

export default useStyles;
