import React, { useContext, useEffect, useState } from "react";
import useStyles from "./style";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { PRODUCT_SUBCATEGORY } from "../../../constants/sub_categories";
import Typography from "@mui/material/Typography";
import { SearchContext } from "../../../context/searchContext";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css";

import "./styles.css";
import { Pagination, Navigation } from "swiper/modules";

const SingleCategory = ({ data, index }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const locationData = useLocation();
  const useQuery = () => {
    const { search } = locationData;
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };
  let query = useQuery();
  const categoryName = query.get("c");
  const subCategoryName = query.get("sc");

  const updateSearchParams = () => {
    const params = new URLSearchParams({
      ["c"]: categoryName,
      ["sc"]: data.value
    });
    navigate(
      { pathname: locationData.pathname, search: params.toString() },
      { replace: true }
    );
  };

  return (
    <div className={classes.categoryItem} onClick={() => updateSearchParams()}>
      <div
        className={`${classes.categoryItemImageContainer} ${
          subCategoryName === data.value ? classes.selectedCategory : ""
        }`}
      >
        <img
          className={classes.categoryImage}
          src={data.imageUrl}
          alt={`sub-category-img-${index}`}
        />
      </div>
      <Typography variant="body1" className={classes.categoryNameTypo}>
        {data.value}
      </Typography>
    </div>
  );
};

const SubCategoriesComponent = () => {
  const [subCatList, setSubCatList] = useState([]);
  const [swiperRef, setSwiperRef] = useState(null);

  const [page, setPage] = useState(0);
  const locationData = useLocation();
  const { locationData: deliveryAddressLocation } = useContext(SearchContext);
  const useQuery = () => {
    const { search } = locationData;
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };
  let query = useQuery();
  const categoryName = query.get("c");
  const subCategoryName = query.get("sc");
  const searchProductName = query.get("s");

  useEffect(() => {
    if (categoryName) {
      const options = PRODUCT_SUBCATEGORY[categoryName];
      setSubCatList(options || []);
    }
  }, [categoryName, locationData, deliveryAddressLocation]);

  return (
    <>
      <Swiper
        slidesPerView={8}
        centeredSlides={false}
        spaceBetween={5}
        longSwipes={true}
        loop={true}
        navigation={true}
        mousewheel={true}
        modules={[Navigation]}
        autoplay={{ delay: 3500 }}
        className="swiper"
      >
        {subCatList.map((subCat, subCatIndex) => (
          <SwiperSlide>
            <SingleCategory data={subCat} index={subCatIndex} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default SubCategoriesComponent;
