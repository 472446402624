import { getCall } from "./axios";

/**
 * function to get all products
 * @returns
 */
export const getAllProductRequest = (params) => {
  console.log("Get all products:", params);
  return new Promise(async (resolve, reject) => {
    try {
      const data = await getCall(`/clientApis/v2/search`, params);
      return resolve(data.response);
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * function to get all products
 * @returns
 */
export const getLatestProducts = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await getCall(`/clientApis/v2/search/latestItems`, params);
      return resolve(data);
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * function to get all products
 * @returns
 */
export const getSalesProducts = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await getCall(`/clientApis/v2/search/salesItems`, params);
      return resolve(data);
      console.log("sales items:", data);
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * function to get all products
 * @returns
 */
export const getBestSellerItems = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await getCall(
        `/clientApis/v2/search/bestSellerItems`,
        params
      );
      console.log("best sellers:", data);
      return resolve(data);
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * function to get all filters
 * @returns
 */
export const getAllFiltersRequest = (subCatName = null, providerId = null) => {
  let params = {};
  if (subCatName) {
    let subCategoryName = subCatName.replace("And", "&");
    params.category = subCategoryName;
  }
  if (providerId) {
    params.provider = providerId;
  } else {
  }
  return new Promise(async (resolve, reject) => {
    try {
      const data = await getCall(`/clientApis/v2/attributes`, params);
      return resolve(data.response);
    } catch (err) {
      return reject(err);
    }
  });
};

/**
 * function to get all filters
 * @returns
 */
export const getAllFilterValuesRequest = (
  attributeCode,
  subCatName = null,
  providerId = null
) => {
  let params = {
    attribute_code: attributeCode
  };
  if (subCatName) {
    let subCategoryName = subCatName.replace("And", "&");
    params.category = subCategoryName;
  }
  if (providerId) {
    params.provider = providerId;
  } else {
  }
  return new Promise(async (resolve, reject) => {
    try {
      const data = await getCall(`/clientApis/v2/attributeValues`, params);
      return resolve(data.response);
    } catch (err) {
      return reject(err);
    }
  });
};
