import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import ToastProvider from "./context/toastContext";
import ErrorBoundary from "./components/shared/error-boundary/errorBoundary";
import { AddressContextProvider } from "./context/addressContext";
import { CartContextProvider } from "./context/cartContext";
import { SearchContextProvider } from "./context/searchContext";
import "animate.css";
// import "swiper/swiper-bundle.min.css";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "./assets/scss/style.scss";
import "./i18n";
import { setProducts } from "./store/slices/product-slice";
import { store } from "./store/store";
import PersistProvider from "./store/providers/persist-provider";
import products from "./data/products.json";
import { Provider } from "react-redux";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./Keycloak";

store.dispatch(setProducts(products));

const container = document.getElementById("root");

const root = ReactDOM.createRoot(container);
root.render(
  <ReactKeycloakProvider
    initOptions={{
      checkLoginIframe: true,
      silentCheckSsoRedirectUri:
        window.location.origin + "/silent-check-sso.html",
      pkceMethod: "S256"
    }}
    authClient={keycloak}
  >
    <ToastProvider>
      <ErrorBoundary>
        <Provider store={store}>
          <PersistProvider>
            {/* <CartContextProvider> */}
            {/*    <AddressContextProvider>*/}
            {/*        <SearchContextProvider>*/}
            <App />

            {/*        </SearchContextProvider>*/}
            {/*    </AddressContextProvider>*/}
            {/* </CartContextProvider> */}
          </PersistProvider>
        </Provider>
      </ErrorBoundary>
    </ToastProvider>
  </ReactKeycloakProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
