import PropTypes from "prop-types";
import clsx from "clsx";

const SectionTitleWithText = ({ spaceTopClass, spaceBottomClass }) => {
  return (
    <div className={clsx("welcome-area", spaceTopClass, spaceBottomClass)}>
      <div className="container">
        <div className="welcome-content text-center">
          <h5>Who Are We</h5>
          <h1>Welcome To Neonyte Retail Marketplace</h1>
          <p>
            We are providing a unique value to our customers. We help you
            discover the products and selling them to you at the best price
            possible. We are participant to ONDC network as well. We bring to
            you pletora of products that are made available by ONDC Network
            Participant Sellers. Our search results and product information is
            powered by GenAI technologies and privacy-compliant. Overall, we are
            striving to ensure your online and offline shopping experience will
            be great!
          </p>
        </div>
      </div>
    </div>
  );
};

SectionTitleWithText.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string
};

export default SectionTitleWithText;
