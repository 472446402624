import React, { useState } from "react";
import useStyles from "./style";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useNavigate, useLocation } from "react-router-dom";

import categoriesData from "./categories.json";

const Categories = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const locationData = useLocation();
  const useQuery = () => {
    const { search } = locationData;
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };
  let query = useQuery();
  const [isViewAllCategories, setIsViewAllCategories] = useState(false);

  const updateQueryParams = (cat) => {
    console.log("Cat:", cat);
    const params = new URLSearchParams({});
    params.set("c", cat.category);
    params.set("domain", cat.domain);
    navigate({
      pathname: "/application/products/",
      search: params.toString()
    });
  };

  return (
    <>
      <Grid
        direction="row"
        spacing={2}
        container
        item
        justifyContent="center"
        padding="5px"
      >
        {categoriesData.map((cat, key) => (
          <Grid key={key} item xs={4} sm={4} md={2} lg={1.2} xl={1.2}>
            <Card
              className={classes.cardContainer}
              onClick={() => updateQueryParams(cat)}
            >
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <CardMedia
                  component="img"
                  className={classes.cardMediaContainer}
                  image={process.env.REACT_APP_PUBLIC_URL + cat.image}
                  alt={cat.category}
                />
                <CardActions>
                  <Button size="small">{cat.title}</Button>
                </CardActions>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Categories;
