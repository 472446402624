import { Fragment } from "react";
import { useLocation } from "react-router-dom";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";

const Returns = () => {
  let { pathname } = useLocation();

  return (
    <Fragment>
      <SEO
        titleTemplate="Returns"
        description="Returns page of Neonyte Retail Marketplace"
      />
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb
          pages={[
            { label: "Home", path: "/" },
            {
              label: "Returns",
              path: pathname
            }
          ]}
        />

        <div className="contact-area pt-100 pb-100">
          <div className="container">
            <div className="custom-row-2">
              <div className="col-12 col-lg-4 col-md-5">
                <div className="contact-info-wrap">
                  <div className="single-contact-info">
                    <div className="contact-icon">
                      <i className="fa fa-phone" />
                    </div>
                    <div className="contact-info-dec">
                      <p>+091 95133 33471</p>
                      <p>+091 95133 33472</p>
                    </div>
                  </div>
                  <div className="single-contact-info">
                    <div className="contact-icon">
                      <i className="fa fa-globe" />
                    </div>
                    <div className="contact-info-dec">
                      <p>
                        <a href="info@simtel.ai">info@simtel.ai</a>
                      </p>
                      <p>
                        <a href="https://simtel.ai">https://simtel.ai</a>
                      </p>
                    </div>
                  </div>
                  <div className="single-contact-info">
                    <div className="contact-icon">
                      <i className="fa fa-map-marker" />
                    </div>
                    <div className="contact-info-dec">
                      <p>No. 36 Emami Nest Flat No 302 </p>
                      <p>8th Main 16th Cross Malleshwaram</p>
                    </div>
                  </div>
                  <div className="contact-social text-center">
                    <h3>Follow Us</h3>
                    <ul>
                      <li>
                        <a href="//facebook.com">
                          <i className="fa fa-facebook" />
                        </a>
                      </li>
                      <li>
                        <a href="//pinterest.com">
                          <i className="fa fa-pinterest-p" />
                        </a>
                      </li>
                      <li>
                        <a href="//thumblr.com">
                          <i className="fa fa-tumblr" />
                        </a>
                      </li>
                      <li>
                        <a href="//vimeo.com">
                          <i className="fa fa-vimeo" />
                        </a>
                      </li>
                      <li>
                        <a href="//twitter.com">
                          <i className="fa fa-twitter" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-8 col-md-7">
                <div class="markdown-heading" dir="auto">
                  <div class="markdown-heading" dir="auto">
                    <h1 class="heading-element" dir="auto" tabindex="-1">
                      RETURN POLICY
                    </h1>
                    <a
                      id="user-content-return-policy"
                      class="anchor"
                      href="https://github.com/ravi-simtel/neonexp-blog/blob/main/src/pages/return_policy.md#return-policy"
                    ></a>
                  </div>
                  <p dir="auto">
                    <code>Last updated Nov 30, 2024</code>
                  </p>
                  <p dir="auto">
                    Thank you for your purchase. We hope you are happy with your
                    purchase. However, if you are not completely satisfied with
                    your purchase for any reason, you may return it to us for a
                    full refund, store credit, or an exchange. Please see below
                    for more information on our return policy.
                  </p>
                  <div class="markdown-heading" dir="auto">
                    <h1 class="heading-element" dir="auto" tabindex="-1">
                      RETURNS
                    </h1>
                    <a
                      id="user-content-returns"
                      class="anchor"
                      href="https://github.com/ravi-simtel/neonexp-blog/blob/main/src/pages/return_policy.md#returns"
                    ></a>
                  </div>
                  <p dir="auto">
                    All returns must be postmarked within seven (7) days of the
                    purchase date. All returned items must be in new and unused
                    condition, with all original tags and labels attached.
                  </p>
                  <div class="markdown-heading" dir="auto">
                    <h1 class="heading-element" dir="auto" tabindex="-1">
                      RETURN PROCESS
                    </h1>
                    <a
                      id="user-content-return-process"
                      class="anchor"
                      href="https://github.com/ravi-simtel/neonexp-blog/blob/main/src/pages/return_policy.md#return-process"
                    ></a>
                  </div>
                  <p dir="auto">
                    To return an item, please email customer service at&nbsp;
                    <a href="mailto:support@neonyte.com">support@neonyte.com</a>
                    &nbsp;to obtain a Return Merchandise Authorisation (RMA)
                    number. Include your phone number and a time that you are
                    comfortable with and we will call you to understand your
                    concerns. If the return meets all criteria listed for
                    returns, then we will share the RMA number via login email.
                  </p>
                  <p dir="auto">
                    After receiving a RMA number, place the item securely in its
                    original packaging and include your proof of purchase, then
                    mail your return to the following address:
                  </p>
                  <div class="snippet-clipboard-content notranslate position-relative overflow-auto">
                    <pre class="notranslate">
                      <code>
                        Simulate Intelligence Private Limited Attn: Returns RMA
                        # 36 Emami Nest Flat No. 302 8th Main 16th Cross
                        Malleshwaram Bangalore, 560055 India
                      </code>
                    </pre>
                    <div class="zeroclipboard-container">&nbsp;</div>
                  </div>
                  <p dir="auto">
                    Return shipping charges will be paid or reimbursed by us.
                  </p>
                  <div class="markdown-heading" dir="auto">
                    <h1 class="heading-element" dir="auto" tabindex="-1">
                      REFUNDS
                    </h1>
                    <a
                      id="user-content-refunds"
                      class="anchor"
                      href="https://github.com/ravi-simtel/neonexp-blog/blob/main/src/pages/return_policy.md#refunds"
                    ></a>
                  </div>
                  <p dir="auto">
                    After receiving your return and inspecting the condition of
                    your item, we will process your return or exchange. Please
                    allow at least fourteen (7) days from the receipt of your
                    item to process your return or exchange. Refunds may take
                    1-2 billing cycles to appear on your credit card statement,
                    depending on your credit card company. We will notify you by
                    email when your return has been processed.
                  </p>
                  <div class="markdown-heading" dir="auto">
                    <h1 class="heading-element" dir="auto" tabindex="-1">
                      EXCEPTIONS
                    </h1>
                    <a
                      id="user-content-exceptions"
                      class="anchor"
                      href="https://github.com/ravi-simtel/neonexp-blog/blob/main/src/pages/return_policy.md#exceptions"
                    ></a>
                  </div>
                  <p dir="auto">
                    The following items cannot be returned or exchanged:
                  </p>
                  <ul dir="auto">
                    <li>
                      Items labeled Non-refundable and/or Non-returnable in
                      description cannot be returned For defective or damaged
                      products, please contact us at the contact details below
                      to arrange a refund or exchange. Please Note
                    </li>
                    <li>
                      A 5% restocking fee will be charged for all returns in
                      excess of Rupees 10,000
                    </li>
                    <li>
                      Returns are valid only when there is a defect in the
                      product.
                    </li>
                    <li>
                      Returns must be packaged well without any damage and
                      labeled as "Handle with care" for elctronics items
                    </li>
                    <li>
                      Returns must be sent within 7 days of receipt of delivery
                    </li>
                    <li>
                      Returns are void if return is not raised with us within 7
                      business days from the receipt of delivery
                    </li>
                  </ul>
                  <div class="markdown-heading" dir="auto">
                    <h1 class="heading-element" dir="auto" tabindex="-1">
                      QUESTIONS
                    </h1>
                    <a
                      id="user-content-questions"
                      class="anchor"
                      href="https://github.com/ravi-simtel/neonexp-blog/blob/main/src/pages/return_policy.md#questions"
                    ></a>
                  </div>
                  <p dir="auto">
                    If you have any questions concerning our return policy,
                    please contact us.
                  </p>
                  <p dir="auto">
                    +919513333471&nbsp;
                    <a href="mailto:support@neonyte.com">support@neonyte.com</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default Returns;
