import React from "react";
import useStyles from "./style";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Grid from "@mui/material";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import vendor from "../../../assets/img/misc/vendor.png";
import { CardMedia } from "@mui/material";

const SingleBrand = ({ data, onMouseOver }) => {
  const { id, provider, domain, provider_descriptor } = data;
  const { name: brand_name, images } = provider_descriptor;

  const classes = useStyles();
  const navigate = useNavigate();

  const redirectBasedOnDomain = () => {
    if (domain === "ONDC:RET11") {
      navigate(`/application/brand?brandId=${provider}&outletId=${id}`);
    } else {
      navigate(`/application/brand?brandId=${provider}`);
    }
  };

  let image_loaded = true;
  let image_url = vendor; // default vendor

  function checkImage(imageSrc) {
    var img = new Image();
    img.onerror = () => {
      image_loaded = false;
    };
    img.src = imageSrc;
  }
  if (images?.length > 0) checkImage(images[0]);

  if (images?.length > 0 && image_loaded) {
    image_url = images[0]; // if image available, replace
  }
  return (
    <div
      direction="row"
      alignContent="center"
      className={classes.brandItemContainer}
      onMouseOver={onMouseOver}
      onClick={() => redirectBasedOnDomain()}
    >
      <Card className={classes.brandCard}>
        <CardMedia
          component="img"
          className={classes.brandImage}
          image={image_url}
          alt="BrandName"
        />
      </Card>
      <Typography variant="body3" sx={{ color: "text.secondary" }}>
        {brand_name}
      </Typography>
    </div>
  );
};

export default SingleBrand;
