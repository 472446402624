import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";

const FooterCopyright = ({ footerLogo, spaceBottomClass, colorClass }) => {
  return (
    <div className={clsx("copyright", spaceBottomClass, colorClass)}>
      <div className="footer-logo">
        <Link to={process.env.REACT_APP_PUBLIC_URL + "/"}>
          <img
            height={"50"}
            alt=""
            src={process.env.REACT_APP_PUBLIC_URL + footerLogo}
          />
        </Link>
      </div>
      <p>
        &copy; {new Date().getFullYear()}{" "}
        <a href="https://simtel.ai" rel="noopener noreferrer" target="_blank">
          Simtel.AI
        </a>
        .<br /> All Rights Reserved
      </p>
    </div>
  );
};

FooterCopyright.propTypes = {
  footerLogo: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  colorClass: PropTypes.string
};

export default FooterCopyright;
