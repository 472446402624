import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_TOKEN, PARSED_TOKEN } from "constants/AuthConstant";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  token:
    localStorage.getItem(AUTH_TOKEN) !== undefined
      ? localStorage.getItem(AUTH_TOKEN)
      : null,
};

export const signIn = createAsyncThunk(
  "auth/login",
  async (data, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem(AUTH_TOKEN);
      localStorage.setItem(AUTH_TOKEN, token);
      console.log("Setting token", token);
      return token;
    } catch (err) {
      return rejectWithValue("Error");
    }
  }
);

export const signOut = createAsyncThunk("auth/logout", async () => {
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(PARSED_TOKEN);
});

export const signInWithGoogle = createAsyncThunk(
  "auth/signInWithGoogle",
  async (_, { rejectWithValue }) => {
    // try {
    //   const response = await AuthService.loginInOAuth();
    //   const token = response.data.token;
    //   localStorage.setItem(AUTH_TOKEN, token);
    //   return token;
    // } catch (err) {
    //   return rejectWithValue(err.response?.data?.message || "Error");
    // }
    return null;
  }
);

export const signInWithFacebook = createAsyncThunk(
  "auth/signInWithFacebook",
  async (_, { rejectWithValue }) => {
    // try {
    //   const response = await AuthService.loginInOAuth();
    //   const token = response.data.token;
    //   localStorage.setItem(AUTH_TOKEN, token);
    //   return token;
    // } catch (err) {
    //   return rejectWithValue(err.response?.data?.message || "Error");
    // }
    return null;
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticated: (state, action) => {
      state.loading = false;
      state.redirect = "/";
      state.token = action.payload;
    },
    showAuthMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
      state.loading = false;
    },
    hideAuthMessage: (state) => {
      state.message = "";
      state.showMessage = false;
    },
    signOutSuccess: (state) => {
      state.loading = false;
      state.token = null;
      state.redirect = "/";
    },
    showLoading: (state) => {
      state.loading = true;
    },
    signInSuccess: (state, action) => {
      state.loading = false;
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signOut.fulfilled, (state) => {
        state.loading = false;
        state.token = null;
        state.redirect = "/";
      })
      .addCase(signOut.rejected, (state) => {
        state.loading = false;
        state.token = null;
        state.redirect = "/";
      })
      .addCase(signInWithGoogle.pending, (state) => {
        state.loading = true;
      })
      .addCase(signInWithGoogle.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signInWithGoogle.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signInWithFacebook.pending, (state) => {
        state.loading = true;
      })
      .addCase(signInWithFacebook.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signInWithFacebook.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      });
  },
});

export const {
  authenticated,
  showAuthMessage,
  hideAuthMessage,
  signOutSuccess,
  showLoading,
  signInSuccess,
} = authSlice.actions;

export default authSlice.reducer;
