export const restoreToDefault = () => {
  return {
    door: "Brigade Woods",
    name: null,
    building: "Vidhan Soudha",
    street: "Vidhan Soudha Road",
    locality: null,
    ward: null,
    city: "Bengaluru",
    state: "Karnataka",
    country: "IND",
    areaCode: "560001",
    tag: "Home",
    lat: "12.979236",
    lng: "77.742382"
  };
};
