import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
];

export const protectedRoutes = [
  {
    key: "dashboard.products",
    path: `${APP_PREFIX_PATH}/dashboards/products`,
    component: React.lazy(() => import("views/app-views/dashboards/products")),
  },
  {
    key: "dashboard.cart",
    path: `${APP_PREFIX_PATH}/dashboards/cart`,
    component: React.lazy(() => import("views/app-views/dashboards/cart")),
  },
  {
    key: "dashboard.userconsent",
    path: `${APP_PREFIX_PATH}/dashboards/userconsent`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/userconsent")
    ),
  },
  {
    key: "dashboard.promotions",
    path: `${APP_PREFIX_PATH}/dashboards/promotions`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/promotions")
    ),
  },
  {
    key: "dashboard.cashback",
    path: `${APP_PREFIX_PATH}/dashboards/cashback`,
    component: React.lazy(() => import("views/app-views/dashboards/cashback")),
  },
  {
    key: "dashboard.orders",
    path: `${APP_PREFIX_PATH}/dashboards/orders`,
    component: React.lazy(() => import("views/app-views/dashboards/orders")),
  },
  {
    key: "dashboard.default",
    path: `${APP_PREFIX_PATH}/dashboards/default`,
    component: React.lazy(() => import("views/app-views/dashboards/default")),
  },
  {
    key: "apps.demograph",
    path: `${APP_PREFIX_PATH}/apps/demograph`,
    component: React.lazy(() => import("views/app-views/apps/demograph")),
  },
  {
    key: "apps.interest",
    path: `${APP_PREFIX_PATH}/apps/interest`,
    component: React.lazy(() => import("views/app-views/apps/interest")),
  },
  {
    key: "discovery.ai",
    path: `${APP_PREFIX_PATH}/discovery/ai`,
    component: React.lazy(() => import("views/app-views/discovery/ai")),
  },
];
