import React, { useContext, useEffect, useRef, useState } from "react";
import useStyles from "./style";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { getAllOutletsFromCategoryAndLocationRequest } from "../../../api/brand.api";
import useCancellablePromise from "../../../api/cancelRequest";

import { ToastContext } from "../../../context/toastContext";
import { toast_actions, toast_types } from "../../shared/toast/utils/toast";
import { useLocation } from "react-router-dom";
import { categoryList } from "../../../constants/categories";
import SingleBrand from "./singleBrand";
import { AddressContext } from "../../../context/addressContext";
import { getValueFromCookie } from "../../../utils/cookies";

import Loading from "../../shared/loading/loading";
import { SearchContext } from "../../../context/searchContext";
import Button from "../../shared/button/button";
import { Box, IconButton } from "@mui/material";
import { ReactComponent as PreviousIcon } from "../../../assets/images/previous.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Brands = ({}) => {
  const observerTarget = useRef(null);

  const classes = useStyles();
  const { locationData: deliveryAddressLocation } = useContext(SearchContext);
  const [category, setCategory] = useState("");
  const [brands, setBrands] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [afterKey, setAfterKey] = useState("");
  const [allRecordsFetched, setAllRecordsFetched] = useState(false);

  const dispatch = useContext(ToastContext);

  // HOOKS
  const { cancellablePromise } = useCancellablePromise();

  const locationData = useLocation();
  const useQuery = () => {
    const { search } = locationData;
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };
  let query = useQuery();

  const resetStates = () => {
    setBrands([]);
    setAfterKey("");
    setAllRecordsFetched(false);
  };

  useEffect(() => {
    const categoryName = query.get("c");
    resetStates();
    if (categoryName) {
      console.log("Category", categoryName);
      getAllBrands(categoryName);
    }
  }, [locationData, deliveryAddressLocation]);

  const getAllBrands = async (categoryName) => {
    let search_cookie = getValueFromCookie("search_context");
    let search_context = undefined;
    if (search_cookie) {
      search_context = JSON.parse(getValueFromCookie("search_context"));
      console.log("serach context:", search_context);
    }
    if (!search_context)
      search_context = {
        location: {
          lat: "12.978994",
          lng: "77.591483"
        }
      };

    let after_key = afterKey;
    let all_records_fetched = allRecordsFetched;

    if (categoryName !== category) {
      resetStates();
      setCategory(categoryName);
      after_key = "";
      all_records_fetched = false;
    }

    const findCategory = categoryList.find(
      (item) => item.name === categoryName
    );
    console.log("findCategory:", findCategory);

    let reqParams = {
      domain: findCategory.domain,
      lat: search_context.location.lat,
      lng: search_context.location.lng,
      afterKey: after_key
    };
    console.log("Search params:", reqParams);

    if (findCategory && !all_records_fetched) {
      try {
        const data = await cancellablePromise(
          getAllOutletsFromCategoryAndLocationRequest(reqParams)
        );
        if (data.data.length > 0) {
          setAfterKey(data.afterKey.location_id);
          setBrands((oldBrands) => {
            // Initially this fn gets called twice, to avoid duplicate data,
            // add data only if existing brands are empty
            if (afterKey ? true : oldBrands.length === 0) {
              return [...oldBrands, ...data.data];
            }
            return oldBrands;
          });
        } else {
          setAllRecordsFetched(true);
        }
      } catch (err) {
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.error,
            message: "Brand Error..."
          }
        });
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAllBrands(query.get("c"));
  }, [isLoading]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsLoading(true);
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget]);

  return (
    <Grid container spacing={5} className={classes.brandContainer}>
      <Box sx={{ borderTop: 1, padding: "50px" }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="h5" sx={{ paddingBottom: "50px" }}>
            Stores near you
          </Typography>
        </Grid>
        <Grid
          container
          spacing={3}
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          ml={"8%"}
          direction="column"
        >
          <Grid
            container
            spacing={5}
            direction={"row"}
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <>
              {brands.length > 0 ? (
                <>
                  {brands.map((item, ind) => (
                    <Grid
                      direction={"row"}
                      container
                      spacing={3}
                      key={`sub-cat-item-${ind}`}
                      item
                      xs={6}
                      sm={6}
                      md={3}
                      lg={3}
                      xl={3}
                    >
                      <SingleBrand data={item} />
                    </Grid>
                  ))}
                </>
              ) : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="body1">...</Typography>
                </Grid>
              )}
            </>
          </Grid>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            alignItems="center"
            mt={2}
          >
            {isLoading && <Loading />}
            <div ref={observerTarget}></div>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};
export default Brands;
