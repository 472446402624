import clsx from "clsx";

import ShopCategoryList from "./ShopCategoryList";

const ShopSideTab = ({
  category_type,
  category,
  categories,
  sideSpaceClass
}) => {
  console.log("category", category);
  console.log("categories:", categories);

  return (
    <div className={clsx("sidebar-style", sideSpaceClass)}>
      <ShopCategoryList
        category_type={category_type}
        category={category}
        categories={categories}
      />
    </div>
  );
};

export default ShopSideTab;
