import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  homeContainer: {
    direction: "column",
    alignContent: "center",
    justifyContent: "center",
    padding: "40px 100px !important"
  }
});

export default useStyles;
