import { Fragment } from "react";
import { useLocation } from "react-router-dom";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";

const Shipping = () => {
  let { pathname } = useLocation();

  return (
    <Fragment>
      <SEO
        titleTemplate="Shipping"
        description="Shipping page of Neonyte Retail Marketplace"
      />
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb
          pages={[
            { label: "Home", path: "/" },
            {
              label: "Shipping",
              path: pathname
            }
          ]}
        />

        <div className="contact-area pt-100 pb-100">
          <div className="container">
            <div className="custom-row-2">
              <div className="col-12 col-lg-4 col-md-5">
                <div className="contact-info-wrap">
                  <div className="single-contact-info">
                    <div className="contact-icon">
                      <i className="fa fa-phone" />
                    </div>
                    <div className="contact-info-dec">
                      <p>+091 95133 33471</p>
                      <p>+091 95133 33472</p>
                    </div>
                  </div>
                  <div className="single-contact-info">
                    <div className="contact-icon">
                      <i className="fa fa-globe" />
                    </div>
                    <div className="contact-info-dec">
                      <p>
                        <a href="info@simtel.ai">info@simtel.ai</a>
                      </p>
                      <p>
                        <a href="https://simtel.ai">https://simtel.ai</a>
                      </p>
                    </div>
                  </div>
                  <div className="single-contact-info">
                    <div className="contact-icon">
                      <i className="fa fa-map-marker" />
                    </div>
                    <div className="contact-info-dec">
                      <p>No. 36 Emami Nest Flat No 302 </p>
                      <p>8th Main 16th Cross Malleshwaram</p>
                    </div>
                  </div>
                  <div className="contact-social text-center">
                    <h3>Follow Us</h3>
                    <ul>
                      <li>
                        <a href="//facebook.com">
                          <i className="fa fa-facebook" />
                        </a>
                      </li>
                      <li>
                        <a href="//pinterest.com">
                          <i className="fa fa-pinterest-p" />
                        </a>
                      </li>
                      <li>
                        <a href="//thumblr.com">
                          <i className="fa fa-tumblr" />
                        </a>
                      </li>
                      <li>
                        <a href="//vimeo.com">
                          <i className="fa fa-vimeo" />
                        </a>
                      </li>
                      <li>
                        <a href="//twitter.com">
                          <i className="fa fa-twitter" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-8 col-md-7">
                <div>
                  <div class="markdown-heading" dir="auto">
                    <div class="markdown-heading" dir="auto">
                      <div class="markdown-heading" dir="auto">
                        <h1 class="heading-element" dir="auto" tabindex="-1">
                          SHIPPING POLICY
                        </h1>
                        <a
                          id="user-content-shipping-policy"
                          class="anchor"
                          href="https://github.com/ravi-simtel/neonexp-blog/blob/main/src/pages/shipping_policy.md#shipping-policy"
                        ></a>
                      </div>
                      <p dir="auto">
                        <code>Last Update:&nbsp;Nov&nbsp;30th, 2024</code>
                      </p>
                      <p dir="auto">
                        This shipping &amp; delivery policy is part of our Terms
                        and Conditions ("Terms") and should therefore read
                        alongside our main terms:&nbsp;
                        <a
                          href="https://blog.neonexp.com/terms_conditions"
                          rel="nofollow"
                        >
                          Terms &amp; Conditions
                        </a>
                      </p>
                      <div class="markdown-heading" dir="auto">
                        <h3 class="heading-element" dir="auto" tabindex="-1">
                          1. WHAT ARE MY SHIPPING AND DELIVERY OPTIONS?
                        </h3>
                        <a
                          id="user-content-1-what-are-my-shipping-and-delivery-options"
                          class="anchor"
                          href="https://github.com/ravi-simtel/neonexp-blog/blob/main/src/pages/shipping_policy.md#1-what-are-my-shipping-and-delivery-options"
                        ></a>
                      </div>
                      <p dir="auto">
                        We clearly specify in the Product Description if a flat
                        shipping fees will be included at the time of checkout,
                        or if shipping fees is made free. For multiple
                        deliveries from different vendors, we calculate total
                        shipping fees at the time of checkout.
                      </p>
                      <div class="markdown-heading" dir="auto">
                        <h3 class="heading-element" dir="auto" tabindex="-1">
                          2. FREE SHIPPING
                        </h3>
                        <a
                          id="user-content-2-free-shipping"
                          class="anchor"
                          href="https://github.com/ravi-simtel/neonexp-blog/blob/main/src/pages/shipping_policy.md#2-free-shipping"
                        ></a>
                      </div>
                      <p dir="auto">
                        We offer free shipping of certain products, and it will
                        be clearly mentioned in the product description. When
                        combined with other products that do not have free
                        shipping options, we will calculate and let you know the
                        shipping cost at the time of checkout.
                      </p>
                      <div class="markdown-heading" dir="auto">
                        <h3 class="heading-element" dir="auto" tabindex="-1">
                          3. ORDER PROCESSING
                        </h3>
                        <a
                          id="user-content-3-order-processing"
                          class="anchor"
                          href="https://github.com/ravi-simtel/neonexp-blog/blob/main/src/pages/shipping_policy.md#3-order-processing"
                        ></a>
                      </div>
                      <div class="snippet-clipboard-content notranslate position-relative overflow-auto">
                        <pre class="notranslate">
                          <code>
                            Please allow 1-business day to process your order.
                            Expect an email within 1 business day to let you
                            know your order is on the way! There is no STORE
                            PICKUP, since we are an online business operating on
                            https://neonyte.com Orders can be picked up daily,
                            from the servicing vendor within 1 Business Day
                            between 9AM and 5PM. In case, the order is placed at
                            night after 5PM, it will be picked up the next
                            Business Day. An email will be sent when your order
                            is ready for pickup.
                          </code>
                        </pre>
                        <div class="zeroclipboard-container">&nbsp;</div>
                      </div>
                      <div class="markdown-heading" dir="auto">
                        <h3 class="heading-element" dir="auto" tabindex="-1">
                          4. LOCAL DELIVERY
                        </h3>
                        <a
                          id="user-content-4-local-delivery"
                          class="anchor"
                          href="https://github.com/ravi-simtel/neonexp-blog/blob/main/src/pages/shipping_policy.md#4-local-delivery"
                        ></a>
                      </div>
                      <p dir="auto">
                        During checkout, enter your shipping address and choose
                        the&nbsp;<code>Checkout</code>&nbsp;option. Enter the
                        shipping address and choose one among the list before
                        clicking on the&nbsp;<code>Checkout</code>&nbsp;button.
                        Local delivery is available for addresses depending on
                        the vendor you are purchasing from. We are a
                        multi-vendor marketplace with vendors all across India.
                        If the local delivery option does not show up during
                        checkout, your order is not eligible for local delivery,
                        which has benefits of same-day or next-day delivery.
                        Orders placed before 5PM during store hours 9AM and 5PM
                        will be delivered at least within 4 Business Days and as
                        early as 1 Business Day. When your order is ready for
                        delivery, you will receive an email with real-time
                        tracking information.
                      </p>
                      <div class="markdown-heading" dir="auto">
                        <h3 class="heading-element" dir="auto" tabindex="-1">
                          5. FLAT RATE SHIPPING
                        </h3>
                        <a
                          id="user-content-5-flat-rate-shipping"
                          class="anchor"
                          href="https://github.com/ravi-simtel/neonexp-blog/blob/main/src/pages/shipping_policy.md#5-flat-rate-shipping"
                        ></a>
                      </div>
                      <p dir="auto">
                        Orders are generally shipped within 9AM and 5PM using
                        our third-party logisitics vendor&nbsp;
                        <a href="https://shiprocket.in/" rel="nofollow">
                          Shiprocket
                        </a>
                        &nbsp;delivery standard is 2 - 5 Business days. Flat
                        rate shipping fee of INR 299 applies to orders shipping
                        to all products unless specified. We do not ship outside
                        of India at this time.
                      </p>
                      <p dir="auto">
                        DOMESTIC SHIPPING RATES AND ESTIMATES:&nbsp;
                        <strong>For calculated shipping rates:</strong>
                        &nbsp;Shipping charges for your order will be calculated
                        and displayed at checkout.&nbsp;
                        <strong>For simple flat rate shipping:</strong>&nbsp;We
                        offer INR 300 flat rate shipping for single products to
                        available regions within India. If multiple products
                        from multiple vendors are checked out, shipping costs
                        may differ and will be notified at the time of checkout.
                        There is a possibility that certain regions in India are
                        not covered for shipping and will be notified at the
                        time of check out. In case, we are unable to deliver, we
                        will intimate by email within 1 Business Day.
                      </p>
                      <div class="markdown-heading" dir="auto">
                        <h3 class="heading-element" dir="auto" tabindex="-1">
                          6. INTERNATIONAL SHIPPING
                        </h3>
                        <a
                          id="user-content-6-international-shipping"
                          class="anchor"
                          href="https://github.com/ravi-simtel/neonexp-blog/blob/main/src/pages/shipping_policy.md#6-international-shipping"
                        ></a>
                      </div>
                      <p dir="auto">We do not offer international shipping</p>
                      <div class="markdown-heading" dir="auto">
                        <h3 class="heading-element" dir="auto" tabindex="-1">
                          7. RETURNS
                        </h3>
                        <a
                          id="user-content-7-returns"
                          class="anchor"
                          href="https://github.com/ravi-simtel/neonexp-blog/blob/main/src/pages/shipping_policy.md#7-returns"
                        ></a>
                      </div>
                      <p dir="auto">
                        If you have questions about returns, please review our
                        return policy&nbsp;
                        <a
                          href="https://blog.neonexp.com/return_policy"
                          rel="nofollow"
                        >
                          Return Policy
                        </a>
                        &nbsp;We address returns on a case-by-case basis but
                        will try our best to work towards a satisfactory
                        solution for you. If you have any further questions,
                        please don't hesitate to contact us at
                      </p>
                      <p dir="auto">
                        +91 95133 33471&nbsp;
                        <a href="mailto:support@neonexp.com">
                          support@neonyte.com
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default Shipping;
