import { Suspense, lazy, React } from "react";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import Cart from "./components/application/cart/cart";

import PageNotFound from "./components/page-not-found/pageNotFound";

import AppLayout from "./components/appLayout";
import PrivateRoute from "./privateRoutes";
import CartContextLayout from "./layouts/CartContextLayout";
import AddressContextLayout from "./layouts/AddressContextLayout";
import SearchContextLayout from "./layouts/SearchContextLayout";
import ProductRoutes from "./components/application/product-list/ProductRoutes";
import BrandRoutes from "./components/brand/BrandRoutes";
import Checkout from "./components/checkout/checkout";
import Orders from "./components/orders/orders";
import OrderDetails from "./components/orders/orderDetails/orderDetails";
import MyTickets from "./components/application/my-tickets/myTickets";
import ComplaintDetail from "./components/application/my-tickets/complaintDetail";
import About from "./pages/other/About";
import Contact from "./pages/other/Contact";
import Privacy from "./pages/other/privacy";
import Returns from "./pages/other/return";
import Shipping from "./pages/other/shipping";
import Terms from "./pages/other/terms";
const HomePage = lazy(() => import("./pages/home/Home"));

export default function OndcRoutes() {
  return (
    <Suspense
      fallback={
        <div className="flone-preloader-wrapper">
          <div className="flone-preloader">
            <span></span>
            <span></span>
          </div>
        </div>
      }
    >
      <Router>
        <Routes>
          <Route element={<CartContextLayout />}>
            <Route element={<AddressContextLayout />}>
              <Route element={<SearchContextLayout />}>
                <Route path={"/"} element={<HomePage />} />
                <Route path={"/about"} element={<About />} />
                <Route path={"/contact"} element={<Contact />} />
                <Route path={"/privacy"} element={<Privacy />} />
                <Route path={"/return"} element={<Returns />} />
                <Route path={"/shipping"} element={<Shipping />} />
                <Route path={"/terms"} element={<Terms />} />

                <Route element={<AppLayout />}>
                  <Route
                    path={"/application"}
                    element={<Navigate to="/application/products" replace />}
                  />

                  <Route
                    path={"/application/products"}
                    element={<ProductRoutes />}
                  />
                  <Route element={<PrivateRoute />}>
                    <Route path={"/application/cart"} element={<Cart />} />
                    <Route
                      path="/application/brand"
                      element={<BrandRoutes />}
                    />
                    <Route
                      path="/application/checkout"
                      element={<Checkout />}
                    />
                    <Route path="/application/orders" element={<Orders />} />
                    <Route
                      path="/application/order/:orderId"
                      element={<OrderDetails />}
                    />
                    <Route
                      path="/application/complaints"
                      element={<MyTickets />}
                    />
                    <Route
                      path="/application/complaint/:issueId"
                      element={<ComplaintDetail />}
                    />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
          <Route path="/page-not-found" element={<PageNotFound />} />

          <Route
            path=""
            component={<Navigate replace to="/page-not-found" />}
          />
        </Routes>
      </Router>
    </Suspense>
  );
}
